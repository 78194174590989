.feedback {
    padding: 80px 0 138px
}

.feedback_header {
    color: #000810;
    margin-left: 30px;
}

.feedback_header .text {
    margin: 20px 0 40px;
    color: #202c38
}

.feedback_header .btn {
    width: 100%
}

.feedback .tns-outer {
    position: relative
}

.feedback .tns-nav {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    bottom: -44px
}

.feedback .tns-nav button {
    margin-right: 15px
}

.feedback .tns-nav button:last-of-type {
    margin-right: 0
}

.feedback_slider {
    margin-top: 30px;
    box-shadow: 0 0 20px 0 rgba(0,0,0,.1);
}

.feedback_slider-slide {
    height: inherit;
    box-shadow: 0 0 20px 0 rgba(0,0,0,.1);
}

.feedback_slider-slide_wrapper {
    height: 100%;
    padding: 30px
}

.feedback_slider-slide_text {
    margin-bottom: 30px;
    color: #000810
}

.feedback_slider-slide_author {
    font-family: Archivo, sans-serif
}

.feedback_slider-slide_author .avatar {
    border-radius: 50%;
    display: block;
    -o-object-fit: cover;
    object-fit: cover;
    width: calc(174px / 3);
    margin-right: 20px
}

.feedback_slider-slide_author .avatar::before {
    float: left;
    padding-top: 100%;
    content: ''
}

.feedback_slider-slide_author .avatar::after {
    display: block;
    content: '';
    clear: both
}

.feedback_slider-slide_author .name {
    font-weight: 600;
    margin-bottom: 5px;
    font-size: 18px;
    line-height: 23px;
    color: #000810
}

.feedback_slider-slide_author .company {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #202c38
}

.feedback_slider-slide_author .company,
.feedback_slider-slide_author .name {
    display: block
}

@media screen and (min-width:575.98px) {
    .feedback_header .btn {
        width: unset
    }
}

@media screen and (min-width:991.98px) {
    .feedback {
        padding: 180px 0
    }


    .feedback .tns-nav {
        left: 0;
        width: auto
    }
}
@media screen and (max-width:767.98px){
    .feedback .wrapper{
        padding-left:30px !important;
        margin-top:30px;
    }
}
@media screen and (min-width:1199.98px) {
    .feedback_slider {
        margin-top: 0
    }

    .feedback_slider-slide_wrapper {
        padding: 60px
    }
}