.addComponentButton{
    position:fixed;
    bottom:20px;
    right:20px;
    display:flex;
    align-items:center;
    justify-content: space-between;
}
.addComponentButton .icon{
    font-size:60px;
    color:#0da574;
    cursor:pointer;
}
.currentMode{
    background:rgb(230, 230, 230);
    padding:20px;
    margin-right:20px;
}
@media screen and (max-width:991.98px){
    .currentMode{
        display:none;
    }
}