body {
    font-family: Roboto,sans-serif;
    font-size: 17px;
    line-height: 1.5;
    color: #000810;
    position: relative
}

body.fixed {
    position: fixed;
    width: 100%
}

body.swal2-shown {
    padding: 0!important
}

.section {
    padding: 80px 0
}

.section-nopb {
    padding: 80px 0 0
}

.section_header .lineTitle {
    text-transform: uppercase;
    padding-left: 60px;
    position: relative;
    display: inline-block;
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 15px;
    font-family: Archivo,sans-serif;
    font-weight: 600
}

.section_header .lineTitle:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 2px;
    width: 40px;
    background: #ffc631
}

.section_header .lineTitle--extended {
    padding-left: 20px;
    margin-left: 40px
}

.section_header .lineTitle--extended:before {
    width: 1000%;
    left: -1000%
}

.section_header .text {
    margin: 20px 0 30px
}

.container,.container-fluid,.container-lg,.container-md,.container-sm,.container-xl,.container-xxl {
    width: 100%;
    padding-right: var(--bs-gutter-x,.75rem);
    padding-left: var(--bs-gutter-x,.75rem);
    margin-right: auto;
    margin-left: auto 
}

@media (min-width:576px) {
    .container,.container-sm {
        max-width: 540px
    }
}

@media (min-width:768px) {
    .container,.container-md,.container-sm {
        max-width: 720px
    }
}

@media (min-width:992px) {
    .container,.container-lg,.container-md,.container-sm {
        max-width: 960px
    }
}

@media (min-width:1200px) {
    .container,.container-lg,.container-md,.container-sm,.container-xl {
        max-width: 1140px
    }
}

@media (min-width:1400px) {
    .container,.container-lg,.container-md,.container-sm,.container-xl,.container-xxl {
        max-width: 1320px
    }
}

.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x))
}

.row>* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y)
}

.col {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%
}

.row-cols-auto>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto
}

.row-cols-1>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%
}

.row-cols-2>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%
}

.row-cols-3>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.3333333333%
}

.row-cols-4>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%
}

.row-cols-5>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%
}

.row-cols-6>* {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.6666666667%
}

.col-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto
}

.col-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 8.33333333%
}

.col-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66666667%
}

.col-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%
}

.col-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333333%
}

.col-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 41.66666667%
}

.col-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%
}

.col-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 58.33333333%
}

.col-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 66.66666667%
}

.col-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 75%
}

.col-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 83.33333333%
}

.col-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 91.66666667%
}

.col-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
}
@media (min-width:992px) {
        .col-lg {
            -webkit-box-flex: 1;
            -ms-flex: 1 0 0%;
            flex: 1 0 0%
        }
    
        .row-cols-lg-auto>* {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            width: auto
        }
    
        .row-cols-lg-1>* {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            width: 100%
        }
    
        .row-cols-lg-2>* {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            width: 50%
        }
    
        .row-cols-lg-3>* {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            width: 33.3333333333%
        }
    
        .row-cols-lg-4>* {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            width: 25%
        }
    
        .row-cols-lg-5>* {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            width: 20%
        }
    
        .row-cols-lg-6>* {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            width: 16.6666666667%
        }
    
        .col-lg-auto {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            width: auto
        }
    
        .col-lg-1 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            width: 8.33333333%
        }
    
        .col-lg-2 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            width: 16.66666667%
        }
    
        .col-lg-3 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            width: 25%
        }
    
        .col-lg-4 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            width: 33.33333333%
        }
    
        .col-lg-5 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            width: 41.66666667%
        }
    
        .col-lg-6 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            width: 50%
        }
    
        .col-lg-7 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            width: 58.33333333%
        }
    
        .col-lg-8 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            width: 66.66666667%
        }
    
        .col-lg-9 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            width: 75%
        }
    
        .col-lg-10 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            width: 83.33333333%
        }
    
        .col-lg-11 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            width: 91.66666667%
        }
    
        .col-lg-12 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            width: 100%
        }
    
        .offset-lg-0 {
            margin-left: 0
        }
    
        .offset-lg-1 {
            margin-left: 8.33333333%
        }
    
        .offset-lg-2 {
            margin-left: 16.66666667%
        }
    
        .offset-lg-3 {
            margin-left: 25%
        }
    
        .offset-lg-4 {
            margin-left: 33.33333333%
        }
    
        .offset-lg-5 {
            margin-left: 41.66666667%
        }
    
        .offset-lg-6 {
            margin-left: 50%
        }
    
        .offset-lg-7 {
            margin-left: 58.33333333%
        }
    
        .offset-lg-8 {
            margin-left: 66.66666667%
        }
    
        .offset-lg-9 {
            margin-left: 75%
        }
    
        .offset-lg-10 {
            margin-left: 83.33333333%
        }
    
        .offset-lg-11 {
            margin-left: 91.66666667%
        }
    
        .g-lg-0,.gx-lg-0 {
            --bs-gutter-x: 0
        }
    
        .g-lg-0,.gy-lg-0 {
            --bs-gutter-y: 0
        }
    
        .g-lg-1,.gx-lg-1 {
            --bs-gutter-x: 0.25rem
        }
    
        .g-lg-1,.gy-lg-1 {
            --bs-gutter-y: 0.25rem
        }
    
        .g-lg-2,.gx-lg-2 {
            --bs-gutter-x: 0.5rem
        }
    
        .g-lg-2,.gy-lg-2 {
            --bs-gutter-y: 0.5rem
        }
    
        .g-lg-3,.gx-lg-3 {
            --bs-gutter-x: 1rem
        }
    
        .g-lg-3,.gy-lg-3 {
            --bs-gutter-y: 1rem
        }
    
        .g-lg-4,.gx-lg-4 {
            --bs-gutter-x: 1.5rem
        }
    
        .g-lg-4,.gy-lg-4 {
            --bs-gutter-y: 1.5rem
        }
    
        .g-lg-5,.gx-lg-5 {
            --bs-gutter-x: 3rem
        }
    
        .g-lg-5,.gy-lg-5 {
            --bs-gutter-y: 3rem
        }
    }
    

@media (min-width:768px) {
    .col-md {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%
    }

    .row-cols-md-auto>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-md-1>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-md-2>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-md-3>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-md-4>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-md-5>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-md-6>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-md-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .col-md-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-md-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-md-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .col-md-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-md-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-md-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .col-md-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-md-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-md-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 75%
    }

    .col-md-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-md-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-md-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .offset-md-0 {
        margin-left: 0
    }

    .offset-md-1 {
        margin-left: 8.33333333%
    }

    .offset-md-2 {
        margin-left: 16.66666667%
    }

    .offset-md-3 {
        margin-left: 25%
    }

    .offset-md-4 {
        margin-left: 33.33333333%
    }

    .offset-md-5 {
        margin-left: 41.66666667%
    }

    .offset-md-6 {
        margin-left: 50%
    }

    .offset-md-7 {
        margin-left: 58.33333333%
    }

    .offset-md-8 {
        margin-left: 66.66666667%
    }

    .offset-md-9 {
        margin-left: 75%
    }

    .offset-md-10 {
        margin-left: 83.33333333%
    }

    .offset-md-11 {
        margin-left: 91.66666667%
    }

    .g-md-0,.gx-md-0 {
        --bs-gutter-x: 0
    }

    .g-md-0,.gy-md-0 {
        --bs-gutter-y: 0
    }

    .g-md-1,.gx-md-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-md-1,.gy-md-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-md-2,.gx-md-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-md-2,.gy-md-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-md-3,.gx-md-3 {
        --bs-gutter-x: 1rem
    }

    .g-md-3,.gy-md-3 {
        --bs-gutter-y: 1rem
    }

    .g-md-4,.gx-md-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-md-4,.gy-md-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-md-5,.gx-md-5 {
        --bs-gutter-x: 3rem
    }

    .g-md-5,.gy-md-5 {
        --bs-gutter-y: 3rem
    }
}
@media (min-width:992px) {
    .col-lg {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%
    }

    .row-cols-lg-auto>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-lg-1>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-lg-2>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-lg-3>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-lg-4>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-lg-5>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-lg-6>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-lg-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .col-lg-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-lg-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-lg-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .col-lg-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-lg-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-lg-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .col-lg-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-lg-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-lg-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 75%
    }

    .col-lg-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-lg-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-lg-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .offset-lg-0 {
        margin-left: 0
    }

    .offset-lg-1 {
        margin-left: 8.33333333%
    }

    .offset-lg-2 {
        margin-left: 16.66666667%
    }

    .offset-lg-3 {
        margin-left: 25%
    }

    .offset-lg-4 {
        margin-left: 33.33333333%
    }

    .offset-lg-5 {
        margin-left: 41.66666667%
    }

    .offset-lg-6 {
        margin-left: 50%
    }

    .offset-lg-7 {
        margin-left: 58.33333333%
    }

    .offset-lg-8 {
        margin-left: 66.66666667%
    }

    .offset-lg-9 {
        margin-left: 75%
    }

    .offset-lg-10 {
        margin-left: 83.33333333%
    }

    .offset-lg-11 {
        margin-left: 91.66666667%
    }

    .g-lg-0,.gx-lg-0 {
        --bs-gutter-x: 0
    }

    .g-lg-0,.gy-lg-0 {
        --bs-gutter-y: 0
    }

    .g-lg-1,.gx-lg-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-lg-1,.gy-lg-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-lg-2,.gx-lg-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-lg-2,.gy-lg-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-lg-3,.gx-lg-3 {
        --bs-gutter-x: 1rem
    }

    .g-lg-3,.gy-lg-3 {
        --bs-gutter-y: 1rem
    }

    .g-lg-4,.gx-lg-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-lg-4,.gy-lg-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-lg-5,.gx-lg-5 {
        --bs-gutter-x: 3rem
    }

    .g-lg-5,.gy-lg-5 {
        --bs-gutter-y: 3rem
    }
}

@media (min-width:992px) {
    .col-lg {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%
    }

    .row-cols-lg-auto>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-lg-1>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-lg-2>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-lg-3>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-lg-4>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-lg-5>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-lg-6>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-lg-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .col-lg-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-lg-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-lg-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .col-lg-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-lg-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-lg-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .col-lg-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-lg-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-lg-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 75%
    }

    .col-lg-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-lg-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-lg-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .offset-lg-0 {
        margin-left: 0
    }

    .offset-lg-1 {
        margin-left: 8.33333333%
    }

    .offset-lg-2 {
        margin-left: 16.66666667%
    }

    .offset-lg-3 {
        margin-left: 25%
    }

    .offset-lg-4 {
        margin-left: 33.33333333%
    }

    .offset-lg-5 {
        margin-left: 41.66666667%
    }

    .offset-lg-6 {
        margin-left: 50%
    }

    .offset-lg-7 {
        margin-left: 58.33333333%
    }

    .offset-lg-8 {
        margin-left: 66.66666667%
    }

    .offset-lg-9 {
        margin-left: 75%
    }

    .offset-lg-10 {
        margin-left: 83.33333333%
    }

    .offset-lg-11 {
        margin-left: 91.66666667%
    }

    .g-lg-0,.gx-lg-0 {
        --bs-gutter-x: 0
    }

    .g-lg-0,.gy-lg-0 {
        --bs-gutter-y: 0
    }

    .g-lg-1,.gx-lg-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-lg-1,.gy-lg-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-lg-2,.gx-lg-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-lg-2,.gy-lg-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-lg-3,.gx-lg-3 {
        --bs-gutter-x: 1rem
    }

    .g-lg-3,.gy-lg-3 {
        --bs-gutter-y: 1rem
    }

    .g-lg-4,.gx-lg-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-lg-4,.gy-lg-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-lg-5,.gx-lg-5 {
        --bs-gutter-x: 3rem
    }

    .g-lg-5,.gy-lg-5 {
        --bs-gutter-y: 3rem
    }
}
@media (min-width:992px) {
    .col-lg {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%
    }

    .row-cols-lg-auto>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-lg-1>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-lg-2>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-lg-3>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-lg-4>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-lg-5>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-lg-6>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-lg-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .col-lg-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-lg-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-lg-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .col-lg-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-lg-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-lg-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .col-lg-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-lg-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-lg-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 75%
    }

    .col-lg-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-lg-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-lg-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .offset-lg-0 {
        margin-left: 0
    }

    .offset-lg-1 {
        margin-left: 8.33333333%
    }

    .offset-lg-2 {
        margin-left: 16.66666667%
    }

    .offset-lg-3 {
        margin-left: 25%
    }

    .offset-lg-4 {
        margin-left: 33.33333333%
    }

    .offset-lg-5 {
        margin-left: 41.66666667%
    }

    .offset-lg-6 {
        margin-left: 50%
    }

    .offset-lg-7 {
        margin-left: 58.33333333%
    }

    .offset-lg-8 {
        margin-left: 66.66666667%
    }

    .offset-lg-9 {
        margin-left: 75%
    }

    .offset-lg-10 {
        margin-left: 83.33333333%
    }

    .offset-lg-11 {
        margin-left: 91.66666667%
    }

    .g-lg-0,.gx-lg-0 {
        --bs-gutter-x: 0
    }

    .g-lg-0,.gy-lg-0 {
        --bs-gutter-y: 0
    }

    .g-lg-1,.gx-lg-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-lg-1,.gy-lg-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-lg-2,.gx-lg-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-lg-2,.gy-lg-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-lg-3,.gx-lg-3 {
        --bs-gutter-x: 1rem
    }

    .g-lg-3,.gy-lg-3 {
        --bs-gutter-y: 1rem
    }

    .g-lg-4,.gx-lg-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-lg-4,.gy-lg-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-lg-5,.gx-lg-5 {
        --bs-gutter-x: 3rem
    }

    .g-lg-5,.gy-lg-5 {
        --bs-gutter-y: 3rem
    }
}
@media (min-width:576px) {
    .d-sm-inline {
        display: inline!important
    }

    .d-sm-inline-block {
        display: inline-block!important
    }

    .d-sm-block {
        display: block!important
    }

    .d-sm-grid {
        display: grid!important
    }

    .d-sm-table {
        display: table!important
    }

    .d-sm-table-row {
        display: table-row!important
    }

    .d-sm-table-cell {
        display: table-cell!important
    }

    .d-sm-flex {
        display: -webkit-box!important;
        display: -ms-flexbox!important;
        display: flex!important
    }

    .d-sm-inline-flex {
        display: -webkit-inline-box!important;
        display: -ms-inline-flexbox!important;
        display: inline-flex!important
    }

    .d-sm-none {
        display: none!important
    }

    .flex-sm-fill {
        -webkit-box-flex: 1!important;
        -ms-flex: 1 1 auto!important;
        flex: 1 1 auto!important
    }

    .flex-sm-row {
        -webkit-box-orient: horizontal!important;
        -webkit-box-direction: normal!important;
        -ms-flex-direction: row!important;
        flex-direction: row!important
    }

    .flex-sm-column {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: normal!important;
        -ms-flex-direction: column!important;
        flex-direction: column!important
    }

    .flex-sm-row-reverse {
        -webkit-box-orient: horizontal!important;
        -webkit-box-direction: reverse!important;
        -ms-flex-direction: row-reverse!important;
        flex-direction: row-reverse!important
    }

    .flex-sm-column-reverse {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: reverse!important;
        -ms-flex-direction: column-reverse!important;
        flex-direction: column-reverse!important
    }

    .flex-sm-grow-0 {
        -webkit-box-flex: 0!important;
        -ms-flex-positive: 0!important;
        flex-grow: 0!important
    }

    .flex-sm-grow-1 {
        -webkit-box-flex: 1!important;
        -ms-flex-positive: 1!important;
        flex-grow: 1!important
    }

    .flex-sm-shrink-0 {
        -ms-flex-negative: 0!important;
        flex-shrink: 0!important
    }

    .flex-sm-shrink-1 {
        -ms-flex-negative: 1!important;
        flex-shrink: 1!important
    }

    .flex-sm-wrap {
        -ms-flex-wrap: wrap!important;
        flex-wrap: wrap!important
    }

    .flex-sm-nowrap {
        -ms-flex-wrap: nowrap!important;
        flex-wrap: nowrap!important
    }

    .flex-sm-wrap-reverse {
        -ms-flex-wrap: wrap-reverse!important;
        flex-wrap: wrap-reverse!important
    }

    .justify-content-sm-start {
        -webkit-box-pack: start!important;
        -ms-flex-pack: start!important;
        justify-content: flex-start!important
    }

    .justify-content-sm-end {
        -webkit-box-pack: end!important;
        -ms-flex-pack: end!important;
        justify-content: flex-end!important
    }

    .justify-content-sm-center {
        -webkit-box-pack: center!important;
        -ms-flex-pack: center!important;
        justify-content: center!important
    }

    .justify-content-sm-between {
        -webkit-box-pack: justify!important;
        -ms-flex-pack: justify!important;
        justify-content: space-between!important
    }

    .justify-content-sm-around {
        -ms-flex-pack: distribute!important;
        justify-content: space-around!important
    }

    .justify-content-sm-evenly {
        -webkit-box-pack: space-evenly!important;
        -ms-flex-pack: space-evenly!important;
        justify-content: space-evenly!important
    }

    .align-items-sm-start {
        -webkit-box-align: start!important;
        -ms-flex-align: start!important;
        align-items: flex-start!important
    }

    .align-items-sm-end {
        -webkit-box-align: end!important;
        -ms-flex-align: end!important;
        align-items: flex-end!important
    }

    .align-items-sm-center {
        -webkit-box-align: center!important;
        -ms-flex-align: center!important;
        align-items: center!important
    }

    .align-items-sm-baseline {
        -webkit-box-align: baseline!important;
        -ms-flex-align: baseline!important;
        align-items: baseline!important
    }

    .align-items-sm-stretch {
        -webkit-box-align: stretch!important;
        -ms-flex-align: stretch!important;
        align-items: stretch!important
    }

    .align-content-sm-start {
        -ms-flex-line-pack: start!important;
        align-content: flex-start!important
    }

    .align-content-sm-end {
        -ms-flex-line-pack: end!important;
        align-content: flex-end!important
    }

    .align-content-sm-center {
        -ms-flex-line-pack: center!important;
        align-content: center!important
    }

    .align-content-sm-between {
        -ms-flex-line-pack: justify!important;
        align-content: space-between!important
    }

    .align-content-sm-around {
        -ms-flex-line-pack: distribute!important;
        align-content: space-around!important
    }

    .align-content-sm-stretch {
        -ms-flex-line-pack: stretch!important;
        align-content: stretch!important
    }

    .align-self-sm-auto {
        -ms-flex-item-align: auto!important;
        align-self: auto!important
    }

    .align-self-sm-start {
        -ms-flex-item-align: start!important;
        align-self: flex-start!important
    }

    .align-self-sm-end {
        -ms-flex-item-align: end!important;
        align-self: flex-end!important
    }

    .align-self-sm-center {
        -ms-flex-item-align: center!important;
        align-self: center!important
    }

    .align-self-sm-baseline {
        -ms-flex-item-align: baseline!important;
        align-self: baseline!important
    }

    .align-self-sm-stretch {
        -ms-flex-item-align: stretch!important;
        align-self: stretch!important
    }

    .order-sm-first {
        -webkit-box-ordinal-group: 0!important;
        -ms-flex-order: -1!important;
        order: -1!important
    }

    .order-sm-0 {
        -webkit-box-ordinal-group: 1!important;
        -ms-flex-order: 0!important;
        order: 0!important
    }

    .order-sm-1 {
        -webkit-box-ordinal-group: 2!important;
        -ms-flex-order: 1!important;
        order: 1!important
    }

    .order-sm-2 {
        -webkit-box-ordinal-group: 3!important;
        -ms-flex-order: 2!important;
        order: 2!important
    }

    .order-sm-3 {
        -webkit-box-ordinal-group: 4!important;
        -ms-flex-order: 3!important;
        order: 3!important
    }

    .order-sm-4 {
        -webkit-box-ordinal-group: 5!important;
        -ms-flex-order: 4!important;
        order: 4!important
    }

    .order-sm-5 {
        -webkit-box-ordinal-group: 6!important;
        -ms-flex-order: 5!important;
        order: 5!important
    }

    .order-sm-last {
        -webkit-box-ordinal-group: 7!important;
        -ms-flex-order: 6!important;
        order: 6!important
    }

    .m-sm-0 {
        margin: 0!important
    }

    .m-sm-1 {
        margin: .25rem!important
    }

    .m-sm-2 {
        margin: .5rem!important
    }

    .m-sm-3 {
        margin: 1rem!important
    }

    .m-sm-4 {
        margin: 1.5rem!important
    }

    .m-sm-5 {
        margin: 3rem!important
    }

    .m-sm-auto {
        margin: auto!important
    }

    .mx-sm-0 {
        margin-right: 0!important;
        margin-left: 0!important
    }

    .mx-sm-1 {
        margin-right: .25rem!important;
        margin-left: .25rem!important
    }

    .mx-sm-2 {
        margin-right: .5rem!important;
        margin-left: .5rem!important
    }

    .mx-sm-3 {
        margin-right: 1rem!important;
        margin-left: 1rem!important
    }

    .mx-sm-4 {
        margin-right: 1.5rem!important;
        margin-left: 1.5rem!important
    }

    .mx-sm-5 {
        margin-right: 3rem!important;
        margin-left: 3rem!important
    }

    .mx-sm-auto {
        margin-right: auto!important;
        margin-left: auto!important
    }

    .my-sm-0 {
        margin-top: 0!important;
        margin-bottom: 0!important
    }

    .my-sm-1 {
        margin-top: .25rem!important;
        margin-bottom: .25rem!important
    }

    .my-sm-2 {
        margin-top: .5rem!important;
        margin-bottom: .5rem!important
    }

    .my-sm-3 {
        margin-top: 1rem!important;
        margin-bottom: 1rem!important
    }

    .my-sm-4 {
        margin-top: 1.5rem!important;
        margin-bottom: 1.5rem!important
    }

    .my-sm-5 {
        margin-top: 3rem!important;
        margin-bottom: 3rem!important
    }

    .my-sm-auto {
        margin-top: auto!important;
        margin-bottom: auto!important
    }

    .mt-sm-0 {
        margin-top: 0!important
    }

    .mt-sm-1 {
        margin-top: .25rem!important
    }

    .mt-sm-2 {
        margin-top: .5rem!important
    }

    .mt-sm-3 {
        margin-top: 1rem!important
    }

    .mt-sm-4 {
        margin-top: 1.5rem!important
    }

    .mt-sm-5 {
        margin-top: 3rem!important
    }

    .mt-sm-auto {
        margin-top: auto!important
    }

    .me-sm-0 {
        margin-right: 0!important
    }

    .me-sm-1 {
        margin-right: .25rem!important
    }

    .me-sm-2 {
        margin-right: .5rem!important
    }

    .me-sm-3 {
        margin-right: 1rem!important
    }

    .me-sm-4 {
        margin-right: 1.5rem!important
    }

    .me-sm-5 {
        margin-right: 3rem!important
    }

    .me-sm-auto {
        margin-right: auto!important
    }

    .mb-sm-0 {
        margin-bottom: 0!important
    }

    .mb-sm-1 {
        margin-bottom: .25rem!important
    }

    .mb-sm-2 {
        margin-bottom: .5rem!important
    }

    .mb-sm-3 {
        margin-bottom: 1rem!important
    }

    .mb-sm-4 {
        margin-bottom: 1.5rem!important
    }

    .mb-sm-5 {
        margin-bottom: 3rem!important
    }

    .mb-sm-auto {
        margin-bottom: auto!important
    }

    .ms-sm-0 {
        margin-left: 0!important
    }

    .ms-sm-1 {
        margin-left: .25rem!important
    }

    .ms-sm-2 {
        margin-left: .5rem!important
    }

    .ms-sm-3 {
        margin-left: 1rem!important
    }

    .ms-sm-4 {
        margin-left: 1.5rem!important
    }

    .ms-sm-5 {
        margin-left: 3rem!important
    }

    .ms-sm-auto {
        margin-left: auto!important
    }

    .p-sm-0 {
        padding: 0!important
    }

    .p-sm-1 {
        padding: .25rem!important
    }

    .p-sm-2 {
        padding: .5rem!important
    }

    .p-sm-3 {
        padding: 1rem!important
    }

    .p-sm-4 {
        padding: 1.5rem!important
    }

    .p-sm-5 {
        padding: 3rem!important
    }

    .px-sm-0 {
        padding-right: 0!important;
        padding-left: 0!important
    }

    .px-sm-1 {
        padding-right: .25rem!important;
        padding-left: .25rem!important
    }

    .px-sm-2 {
        padding-right: .5rem!important;
        padding-left: .5rem!important
    }

    .px-sm-3 {
        padding-right: 1rem!important;
        padding-left: 1rem!important
    }

    .px-sm-4 {
        padding-right: 1.5rem!important;
        padding-left: 1.5rem!important
    }

    .px-sm-5 {
        padding-right: 3rem!important;
        padding-left: 3rem!important
    }

    .py-sm-0 {
        padding-top: 0!important;
        padding-bottom: 0!important
    }

    .py-sm-1 {
        padding-top: .25rem!important;
        padding-bottom: .25rem!important
    }

    .py-sm-2 {
        padding-top: .5rem!important;
        padding-bottom: .5rem!important
    }

    .py-sm-3 {
        padding-top: 1rem!important;
        padding-bottom: 1rem!important
    }

    .py-sm-4 {
        padding-top: 1.5rem!important;
        padding-bottom: 1.5rem!important
    }

    .py-sm-5 {
        padding-top: 3rem!important;
        padding-bottom: 3rem!important
    }

    .pt-sm-0 {
        padding-top: 0!important
    }

    .pt-sm-1 {
        padding-top: .25rem!important
    }

    .pt-sm-2 {
        padding-top: .5rem!important
    }

    .pt-sm-3 {
        padding-top: 1rem!important
    }

    .pt-sm-4 {
        padding-top: 1.5rem!important
    }

    .pt-sm-5 {
        padding-top: 3rem!important
    }

    .pe-sm-0 {
        padding-right: 0!important
    }

    .pe-sm-1 {
        padding-right: .25rem!important
    }

    .pe-sm-2 {
        padding-right: .5rem!important
    }

    .pe-sm-3 {
        padding-right: 1rem!important
    }

    .pe-sm-4 {
        padding-right: 1.5rem!important
    }

    .pe-sm-5 {
        padding-right: 3rem!important
    }

    .pb-sm-0 {
        padding-bottom: 0!important
    }

    .pb-sm-1 {
        padding-bottom: .25rem!important
    }

    .pb-sm-2 {
        padding-bottom: .5rem!important
    }

    .pb-sm-3 {
        padding-bottom: 1rem!important
    }

    .pb-sm-4 {
        padding-bottom: 1.5rem!important
    }

    .pb-sm-5 {
        padding-bottom: 3rem!important
    }

    .ps-sm-0 {
        padding-left: 0!important
    }

    .ps-sm-1 {
        padding-left: .25rem!important
    }

    .ps-sm-2 {
        padding-left: .5rem!important
    }

    .ps-sm-3 {
        padding-left: 1rem!important
    }

    .ps-sm-4 {
        padding-left: 1.5rem!important
    }

    .ps-sm-5 {
        padding-left: 3rem!important
    }
}

@media (min-width:1200px) {
    .d-xl-inline {
        display: inline!important
    }

    .d-xl-inline-block {
        display: inline-block!important
    }

    .d-xl-block {
        display: block!important
    }

    .d-xl-grid {
        display: grid!important
    }

    .d-xl-table {
        display: table!important
    }

    .d-xl-table-row {
        display: table-row!important
    }

    .d-xl-table-cell {
        display: table-cell!important
    }

    .d-xl-flex {
        display: -webkit-box!important;
        display: -ms-flexbox!important;
        display: flex!important
    }

    .d-xl-inline-flex {
        display: -webkit-inline-box!important;
        display: -ms-inline-flexbox!important;
        display: inline-flex!important
    }

    .d-xl-none {
        display: none!important
    }

    .flex-xl-fill {
        -webkit-box-flex: 1!important;
        -ms-flex: 1 1 auto!important;
        flex: 1 1 auto!important
    }

    .flex-xl-row {
        -webkit-box-orient: horizontal!important;
        -webkit-box-direction: normal!important;
        -ms-flex-direction: row!important;
        flex-direction: row!important
    }

    .flex-xl-column {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: normal!important;
        -ms-flex-direction: column!important;
        flex-direction: column!important
    }

    .flex-xl-row-reverse {
        -webkit-box-orient: horizontal!important;
        -webkit-box-direction: reverse!important;
        -ms-flex-direction: row-reverse!important;
        flex-direction: row-reverse!important
    }

    .flex-xl-column-reverse {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: reverse!important;
        -ms-flex-direction: column-reverse!important;
        flex-direction: column-reverse!important
    }

    .flex-xl-grow-0 {
        -webkit-box-flex: 0!important;
        -ms-flex-positive: 0!important;
        flex-grow: 0!important
    }

    .flex-xl-grow-1 {
        -webkit-box-flex: 1!important;
        -ms-flex-positive: 1!important;
        flex-grow: 1!important
    }

    .flex-xl-shrink-0 {
        -ms-flex-negative: 0!important;
        flex-shrink: 0!important
    }

    .flex-xl-shrink-1 {
        -ms-flex-negative: 1!important;
        flex-shrink: 1!important
    }

    .flex-xl-wrap {
        -ms-flex-wrap: wrap!important;
        flex-wrap: wrap!important
    }

    .flexXlNoWrap {
        -ms-flex-wrap: nowrap!important;
        flex-wrap: nowrap!important
    }

    .flex-xl-wrap-reverse {
        -ms-flex-wrap: wrap-reverse!important;
        flex-wrap: wrap-reverse!important
    }

    .justify-content-xl-start {
        -webkit-box-pack: start!important;
        -ms-flex-pack: start!important;
        justify-content: flex-start!important
    }

    .justify-content-xl-end {
        -webkit-box-pack: end!important;
        -ms-flex-pack: end!important;
        justify-content: flex-end!important
    }

    .justify-content-xl-center {
        -webkit-box-pack: center!important;
        -ms-flex-pack: center!important;
        justify-content: center!important
    }

    .justify-content-xl-between {
        -webkit-box-pack: justify!important;
        -ms-flex-pack: justify!important;
        justify-content: space-between!important
    }

    .justify-content-xl-around {
        -ms-flex-pack: distribute!important;
        justify-content: space-around!important
    }

    .justify-content-xl-evenly {
        -webkit-box-pack: space-evenly!important;
        -ms-flex-pack: space-evenly!important;
        justify-content: space-evenly!important
    }

    .align-items-xl-start {
        -webkit-box-align: start!important;
        -ms-flex-align: start!important;
        align-items: flex-start!important
    }

    .align-items-xl-end {
        -webkit-box-align: end!important;
        -ms-flex-align: end!important;
        align-items: flex-end!important
    }

    .alignItemsXlCenter {
        -webkit-box-align: center!important;
        -ms-flex-align: center!important;
        align-items: center!important
    }

    .align-items-xl-baseline {
        -webkit-box-align: baseline!important;
        -ms-flex-align: baseline!important;
        align-items: baseline!important
    }

    .align-items-xl-stretch {
        -webkit-box-align: stretch!important;
        -ms-flex-align: stretch!important;
        align-items: stretch!important
    }

    .align-content-xl-start {
        -ms-flex-line-pack: start!important;
        align-content: flex-start!important
    }

    .align-content-xl-end {
        -ms-flex-line-pack: end!important;
        align-content: flex-end!important
    }

    .align-content-xl-center {
        -ms-flex-line-pack: center!important;
        align-content: center!important
    }

    .align-content-xl-between {
        -ms-flex-line-pack: justify!important;
        align-content: space-between!important
    }

    .align-content-xl-around {
        -ms-flex-line-pack: distribute!important;
        align-content: space-around!important
    }

    .align-content-xl-stretch {
        -ms-flex-line-pack: stretch!important;
        align-content: stretch!important
    }

    .align-self-xl-auto {
        -ms-flex-item-align: auto!important;
        align-self: auto!important
    }

    .align-self-xl-start {
        -ms-flex-item-align: start!important;
        align-self: flex-start!important
    }

    .align-self-xl-end {
        -ms-flex-item-align: end!important;
        align-self: flex-end!important
    }

    .align-self-xl-center {
        -ms-flex-item-align: center!important;
        align-self: center!important
    }

    .align-self-xl-baseline {
        -ms-flex-item-align: baseline!important;
        align-self: baseline!important
    }

    .align-self-xl-stretch {
        -ms-flex-item-align: stretch!important;
        align-self: stretch!important
    }

    .order-xl-first {
        -webkit-box-ordinal-group: 0!important;
        -ms-flex-order: -1!important;
        order: -1!important
    }

    .order-xl-0 {
        -webkit-box-ordinal-group: 1!important;
        -ms-flex-order: 0!important;
        order: 0!important
    }

    .order-xl-1 {
        -webkit-box-ordinal-group: 2!important;
        -ms-flex-order: 1!important;
        order: 1!important
    }

    .order-xl-2 {
        -webkit-box-ordinal-group: 3!important;
        -ms-flex-order: 2!important;
        order: 2!important
    }

    .order-xl-3 {
        -webkit-box-ordinal-group: 4!important;
        -ms-flex-order: 3!important;
        order: 3!important
    }

    .order-xl-4 {
        -webkit-box-ordinal-group: 5!important;
        -ms-flex-order: 4!important;
        order: 4!important
    }

    .order-xl-5 {
        -webkit-box-ordinal-group: 6!important;
        -ms-flex-order: 5!important;
        order: 5!important
    }

    .order-xl-last {
        -webkit-box-ordinal-group: 7!important;
        -ms-flex-order: 6!important;
        order: 6!important
    }

    .m-xl-0 {
        margin: 0!important
    }

    .m-xl-1 {
        margin: .25rem!important
    }

    .m-xl-2 {
        margin: .5rem!important
    }

    .m-xl-3 {
        margin: 1rem!important
    }

    .m-xl-4 {
        margin: 1.5rem!important
    }

    .m-xl-5 {
        margin: 3rem!important
    }

    .m-xl-auto {
        margin: auto!important
    }

    .mx-xl-0 {
        margin-right: 0!important;
        margin-left: 0!important
    }

    .mx-xl-1 {
        margin-right: .25rem!important;
        margin-left: .25rem!important
    }

    .mx-xl-2 {
        margin-right: .5rem!important;
        margin-left: .5rem!important
    }

    .mx-xl-3 {
        margin-right: 1rem!important;
        margin-left: 1rem!important
    }

    .mx-xl-4 {
        margin-right: 1.5rem!important;
        margin-left: 1.5rem!important
    }

    .mx-xl-5 {
        margin-right: 3rem!important;
        margin-left: 3rem!important
    }

    .mx-xl-auto {
        margin-right: auto!important;
        margin-left: auto!important
    }

    .my-xl-0 {
        margin-top: 0!important;
        margin-bottom: 0!important
    }

    .my-xl-1 {
        margin-top: .25rem!important;
        margin-bottom: .25rem!important
    }

    .my-xl-2 {
        margin-top: .5rem!important;
        margin-bottom: .5rem!important
    }

    .my-xl-3 {
        margin-top: 1rem!important;
        margin-bottom: 1rem!important
    }

    .my-xl-4 {
        margin-top: 1.5rem!important;
        margin-bottom: 1.5rem!important
    }

    .my-xl-5 {
        margin-top: 3rem!important;
        margin-bottom: 3rem!important
    }

    .my-xl-auto {
        margin-top: auto!important;
        margin-bottom: auto!important
    }

    .mt-xl-0 {
        margin-top: 0!important
    }

    .mt-xl-1 {
        margin-top: .25rem!important
    }

    .mt-xl-2 {
        margin-top: .5rem!important
    }

    .mt-xl-3 {
        margin-top: 1rem!important
    }

    .mt-xl-4 {
        margin-top: 1.5rem!important
    }

    .mt-xl-5 {
        margin-top: 3rem!important
    }

    .mt-xl-auto {
        margin-top: auto!important
    }

    .me-xl-0 {
        margin-right: 0!important
    }

    .me-xl-1 {
        margin-right: .25rem!important
    }

    .me-xl-2 {
        margin-right: .5rem!important
    }

    .me-xl-3 {
        margin-right: 1rem!important
    }

    .me-xl-4 {
        margin-right: 1.5rem!important
    }

    .me-xl-5 {
        margin-right: 3rem!important
    }

    .me-xl-auto {
        margin-right: auto!important
    }

    .mb-xl-0 {
        margin-bottom: 0!important
    }

    .mb-xl-1 {
        margin-bottom: .25rem!important
    }

    .mb-xl-2 {
        margin-bottom: .5rem!important
    }

    .mb-xl-3 {
        margin-bottom: 1rem!important
    }

    .mb-xl-4 {
        margin-bottom: 1.5rem!important
    }

    .mb-xl-5 {
        margin-bottom: 3rem!important
    }

    .mb-xl-auto {
        margin-bottom: auto!important
    }

    .ms-xl-0 {
        margin-left: 0!important
    }

    .ms-xl-1 {
        margin-left: .25rem!important
    }

    .ms-xl-2 {
        margin-left: .5rem!important
    }

    .ms-xl-3 {
        margin-left: 1rem!important
    }

    .ms-xl-4 {
        margin-left: 1.5rem!important
    }

    .ms-xl-5 {
        margin-left: 3rem!important
    }

    .ms-xl-auto {
        margin-left: auto!important
    }

    .p-xl-0 {
        padding: 0!important
    }

    .p-xl-1 {
        padding: .25rem!important
    }

    .p-xl-2 {
        padding: .5rem!important
    }

    .p-xl-3 {
        padding: 1rem!important
    }

    .p-xl-4 {
        padding: 1.5rem!important
    }

    .p-xl-5 {
        padding: 3rem!important
    }

    .px-xl-0 {
        padding-right: 0!important;
        padding-left: 0!important
    }

    .px-xl-1 {
        padding-right: .25rem!important;
        padding-left: .25rem!important
    }

    .px-xl-2 {
        padding-right: .5rem!important;
        padding-left: .5rem!important
    }

    .px-xl-3 {
        padding-right: 1rem!important;
        padding-left: 1rem!important
    }

    .px-xl-4 {
        padding-right: 1.5rem!important;
        padding-left: 1.5rem!important
    }

    .px-xl-5 {
        padding-right: 3rem!important;
        padding-left: 3rem!important
    }

    .py-xl-0 {
        padding-top: 0!important;
        padding-bottom: 0!important
    }

    .py-xl-1 {
        padding-top: .25rem!important;
        padding-bottom: .25rem!important
    }

    .py-xl-2 {
        padding-top: .5rem!important;
        padding-bottom: .5rem!important
    }

    .py-xl-3 {
        padding-top: 1rem!important;
        padding-bottom: 1rem!important
    }

    .py-xl-4 {
        padding-top: 1.5rem!important;
        padding-bottom: 1.5rem!important
    }

    .py-xl-5 {
        padding-top: 3rem!important;
        padding-bottom: 3rem!important
    }

    .pt-xl-0 {
        padding-top: 0!important
    }

    .pt-xl-1 {
        padding-top: .25rem!important
    }

    .pt-xl-2 {
        padding-top: .5rem!important
    }

    .pt-xl-3 {
        padding-top: 1rem!important
    }

    .pt-xl-4 {
        padding-top: 1.5rem!important
    }

    .pt-xl-5 {
        padding-top: 3rem!important
    }

    .pe-xl-0 {
        padding-right: 0!important
    }

    .pe-xl-1 {
        padding-right: .25rem!important
    }

    .pe-xl-2 {
        padding-right: .5rem!important
    }

    .pe-xl-3 {
        padding-right: 1rem!important
    }

    .pe-xl-4 {
        padding-right: 1.5rem!important
    }

    .pe-xl-5 {
        padding-right: 3rem!important
    }

    .pb-xl-0 {
        padding-bottom: 0!important
    }

    .pb-xl-1 {
        padding-bottom: .25rem!important
    }

    .pb-xl-2 {
        padding-bottom: .5rem!important
    }

    .pb-xl-3 {
        padding-bottom: 1rem!important
    }

    .pb-xl-4 {
        padding-bottom: 1.5rem!important
    }

    .pb-xl-5 {
        padding-bottom: 3rem!important
    }

    .ps-xl-0 {
        padding-left: 0!important
    }

    .ps-xl-1 {
        padding-left: .25rem!important
    }

    .ps-xl-2 {
        padding-left: .5rem!important
    }

    .ps-xl-3 {
        padding-left: 1rem!important
    }

    .ps-xl-4 {
        padding-left: 1.5rem!important
    }

    .ps-xl-5 {
        padding-left: 3rem!important
    }
}

@media (min-width:1400px) {
    .d-xxl-inline {
        display: inline!important
    }

    .d-xxl-inline-block {
        display: inline-block!important
    }

    .d-xxl-block {
        display: block!important
    }

    .d-xxl-grid {
        display: grid!important
    }

    .d-xxl-table {
        display: table!important
    }

    .d-xxl-table-row {
        display: table-row!important
    }

    .d-xxl-table-cell {
        display: table-cell!important
    }

    .d-xxl-flex {
        display: -webkit-box!important;
        display: -ms-flexbox!important;
        display: flex!important
    }

    .d-xxl-inline-flex {
        display: -webkit-inline-box!important;
        display: -ms-inline-flexbox!important;
        display: inline-flex!important
    }

    .d-xxl-none {
        display: none!important
    }

    .flex-xxl-fill {
        -webkit-box-flex: 1!important;
        -ms-flex: 1 1 auto!important;
        flex: 1 1 auto!important
    }

    .flex-xxl-row {
        -webkit-box-orient: horizontal!important;
        -webkit-box-direction: normal!important;
        -ms-flex-direction: row!important;
        flex-direction: row!important
    }

    .flex-xxl-column {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: normal!important;
        -ms-flex-direction: column!important;
        flex-direction: column!important
    }

    .flex-xxl-row-reverse {
        -webkit-box-orient: horizontal!important;
        -webkit-box-direction: reverse!important;
        -ms-flex-direction: row-reverse!important;
        flex-direction: row-reverse!important
    }

    .flex-xxl-column-reverse {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: reverse!important;
        -ms-flex-direction: column-reverse!important;
        flex-direction: column-reverse!important
    }

    .flex-xxl-grow-0 {
        -webkit-box-flex: 0!important;
        -ms-flex-positive: 0!important;
        flex-grow: 0!important
    }

    .flex-xxl-grow-1 {
        -webkit-box-flex: 1!important;
        -ms-flex-positive: 1!important;
        flex-grow: 1!important
    }

    .flex-xxl-shrink-0 {
        -ms-flex-negative: 0!important;
        flex-shrink: 0!important
    }

    .flex-xxl-shrink-1 {
        -ms-flex-negative: 1!important;
        flex-shrink: 1!important
    }

    .flex-xxl-wrap {
        -ms-flex-wrap: wrap!important;
        flex-wrap: wrap!important
    }

    .flex-xxl-nowrap {
        -ms-flex-wrap: nowrap!important;
        flex-wrap: nowrap!important
    }

    .flex-xxl-wrap-reverse {
        -ms-flex-wrap: wrap-reverse!important;
        flex-wrap: wrap-reverse!important
    }

    .justify-content-xxl-start {
        -webkit-box-pack: start!important;
        -ms-flex-pack: start!important;
        justify-content: flex-start!important
    }

    .justify-content-xxl-end {
        -webkit-box-pack: end!important;
        -ms-flex-pack: end!important;
        justify-content: flex-end!important
    }

    .justify-content-xxl-center {
        -webkit-box-pack: center!important;
        -ms-flex-pack: center!important;
        justify-content: center!important
    }

    .justify-content-xxl-between {
        -webkit-box-pack: justify!important;
        -ms-flex-pack: justify!important;
        justify-content: space-between!important
    }

    .justify-content-xxl-around {
        -ms-flex-pack: distribute!important;
        justify-content: space-around!important
    }

    .justify-content-xxl-evenly {
        -webkit-box-pack: space-evenly!important;
        -ms-flex-pack: space-evenly!important;
        justify-content: space-evenly!important
    }

    .align-items-xxl-start {
        -webkit-box-align: start!important;
        -ms-flex-align: start!important;
        align-items: flex-start!important
    }

    .align-items-xxl-end {
        -webkit-box-align: end!important;
        -ms-flex-align: end!important;
        align-items: flex-end!important
    }

    .align-items-xxl-center {
        -webkit-box-align: center!important;
        -ms-flex-align: center!important;
        align-items: center!important
    }

    .align-items-xxl-baseline {
        -webkit-box-align: baseline!important;
        -ms-flex-align: baseline!important;
        align-items: baseline!important
    }

    .align-items-xxl-stretch {
        -webkit-box-align: stretch!important;
        -ms-flex-align: stretch!important;
        align-items: stretch!important
    }

    .align-content-xxl-start {
        -ms-flex-line-pack: start!important;
        align-content: flex-start!important
    }

    .align-content-xxl-end {
        -ms-flex-line-pack: end!important;
        align-content: flex-end!important
    }

    .align-content-xxl-center {
        -ms-flex-line-pack: center!important;
        align-content: center!important
    }

    .align-content-xxl-between {
        -ms-flex-line-pack: justify!important;
        align-content: space-between!important
    }

    .align-content-xxl-around {
        -ms-flex-line-pack: distribute!important;
        align-content: space-around!important
    }

    .align-content-xxl-stretch {
        -ms-flex-line-pack: stretch!important;
        align-content: stretch!important
    }

    .align-self-xxl-auto {
        -ms-flex-item-align: auto!important;
        align-self: auto!important
    }

    .align-self-xxl-start {
        -ms-flex-item-align: start!important;
        align-self: flex-start!important
    }

    .align-self-xxl-end {
        -ms-flex-item-align: end!important;
        align-self: flex-end!important
    }

    .align-self-xxl-center {
        -ms-flex-item-align: center!important;
        align-self: center!important
    }

    .align-self-xxl-baseline {
        -ms-flex-item-align: baseline!important;
        align-self: baseline!important
    }

    .align-self-xxl-stretch {
        -ms-flex-item-align: stretch!important;
        align-self: stretch!important
    }

    .order-xxl-first {
        -webkit-box-ordinal-group: 0!important;
        -ms-flex-order: -1!important;
        order: -1!important
    }

    .order-xxl-0 {
        -webkit-box-ordinal-group: 1!important;
        -ms-flex-order: 0!important;
        order: 0!important
    }

    .order-xxl-1 {
        -webkit-box-ordinal-group: 2!important;
        -ms-flex-order: 1!important;
        order: 1!important
    }

    .order-xxl-2 {
        -webkit-box-ordinal-group: 3!important;
        -ms-flex-order: 2!important;
        order: 2!important
    }

    .order-xxl-3 {
        -webkit-box-ordinal-group: 4!important;
        -ms-flex-order: 3!important;
        order: 3!important
    }

    .order-xxl-4 {
        -webkit-box-ordinal-group: 5!important;
        -ms-flex-order: 4!important;
        order: 4!important
    }

    .order-xxl-5 {
        -webkit-box-ordinal-group: 6!important;
        -ms-flex-order: 5!important;
        order: 5!important
    }

    .order-xxl-last {
        -webkit-box-ordinal-group: 7!important;
        -ms-flex-order: 6!important;
        order: 6!important
    }

    .m-xxl-0 {
        margin: 0!important
    }

    .m-xxl-1 {
        margin: .25rem!important
    }

    .m-xxl-2 {
        margin: .5rem!important
    }

    .m-xxl-3 {
        margin: 1rem!important
    }

    .m-xxl-4 {
        margin: 1.5rem!important
    }

    .m-xxl-5 {
        margin: 3rem!important
    }

    .m-xxl-auto {
        margin: auto!important
    }

    .mx-xxl-0 {
        margin-right: 0!important;
        margin-left: 0!important
    }

    .mx-xxl-1 {
        margin-right: .25rem!important;
        margin-left: .25rem!important
    }

    .mx-xxl-2 {
        margin-right: .5rem!important;
        margin-left: .5rem!important
    }

    .mx-xxl-3 {
        margin-right: 1rem!important;
        margin-left: 1rem!important
    }

    .mx-xxl-4 {
        margin-right: 1.5rem!important;
        margin-left: 1.5rem!important
    }

    .mx-xxl-5 {
        margin-right: 3rem!important;
        margin-left: 3rem!important
    }

    .mx-xxl-auto {
        margin-right: auto!important;
        margin-left: auto!important
    }

    .my-xxl-0 {
        margin-top: 0!important;
        margin-bottom: 0!important
    }

    .my-xxl-1 {
        margin-top: .25rem!important;
        margin-bottom: .25rem!important
    }

    .my-xxl-2 {
        margin-top: .5rem!important;
        margin-bottom: .5rem!important
    }

    .my-xxl-3 {
        margin-top: 1rem!important;
        margin-bottom: 1rem!important
    }

    .my-xxl-4 {
        margin-top: 1.5rem!important;
        margin-bottom: 1.5rem!important
    }

    .my-xxl-5 {
        margin-top: 3rem!important;
        margin-bottom: 3rem!important
    }

    .my-xxl-auto {
        margin-top: auto!important;
        margin-bottom: auto!important
    }

    .mt-xxl-0 {
        margin-top: 0!important
    }

    .mt-xxl-1 {
        margin-top: .25rem!important
    }

    .mt-xxl-2 {
        margin-top: .5rem!important
    }

    .mt-xxl-3 {
        margin-top: 1rem!important
    }

    .mt-xxl-4 {
        margin-top: 1.5rem!important
    }

    .mt-xxl-5 {
        margin-top: 3rem!important
    }

    .mt-xxl-auto {
        margin-top: auto!important
    }

    .me-xxl-0 {
        margin-right: 0!important
    }

    .me-xxl-1 {
        margin-right: .25rem!important
    }

    .me-xxl-2 {
        margin-right: .5rem!important
    }

    .me-xxl-3 {
        margin-right: 1rem!important
    }

    .me-xxl-4 {
        margin-right: 1.5rem!important
    }

    .me-xxl-5 {
        margin-right: 3rem!important
    }

    .me-xxl-auto {
        margin-right: auto!important
    }

    .mb-xxl-0 {
        margin-bottom: 0!important
    }

    .mb-xxl-1 {
        margin-bottom: .25rem!important
    }

    .mb-xxl-2 {
        margin-bottom: .5rem!important
    }

    .mb-xxl-3 {
        margin-bottom: 1rem!important
    }

    .mb-xxl-4 {
        margin-bottom: 1.5rem!important
    }

    .mb-xxl-5 {
        margin-bottom: 3rem!important
    }

    .mb-xxl-auto {
        margin-bottom: auto!important
    }

    .ms-xxl-0 {
        margin-left: 0!important
    }

    .ms-xxl-1 {
        margin-left: .25rem!important
    }

    .ms-xxl-2 {
        margin-left: .5rem!important
    }

    .ms-xxl-3 {
        margin-left: 1rem!important
    }

    .ms-xxl-4 {
        margin-left: 1.5rem!important
    }

    .ms-xxl-5 {
        margin-left: 3rem!important
    }

    .ms-xxl-auto {
        margin-left: auto!important
    }

    .p-xxl-0 {
        padding: 0!important
    }

    .p-xxl-1 {
        padding: .25rem!important
    }

    .p-xxl-2 {
        padding: .5rem!important
    }

    .p-xxl-3 {
        padding: 1rem!important
    }

    .p-xxl-4 {
        padding: 1.5rem!important
    }

    .p-xxl-5 {
        padding: 3rem!important
    }

    .px-xxl-0 {
        padding-right: 0!important;
        padding-left: 0!important
    }

    .px-xxl-1 {
        padding-right: .25rem!important;
        padding-left: .25rem!important
    }

    .px-xxl-2 {
        padding-right: .5rem!important;
        padding-left: .5rem!important
    }

    .px-xxl-3 {
        padding-right: 1rem!important;
        padding-left: 1rem!important
    }

    .px-xxl-4 {
        padding-right: 1.5rem!important;
        padding-left: 1.5rem!important
    }

    .px-xxl-5 {
        padding-right: 3rem!important;
        padding-left: 3rem!important
    }

    .py-xxl-0 {
        padding-top: 0!important;
        padding-bottom: 0!important
    }

    .py-xxl-1 {
        padding-top: .25rem!important;
        padding-bottom: .25rem!important
    }

    .py-xxl-2 {
        padding-top: .5rem!important;
        padding-bottom: .5rem!important
    }

    .py-xxl-3 {
        padding-top: 1rem!important;
        padding-bottom: 1rem!important
    }

    .py-xxl-4 {
        padding-top: 1.5rem!important;
        padding-bottom: 1.5rem!important
    }

    .py-xxl-5 {
        padding-top: 3rem!important;
        padding-bottom: 3rem!important
    }

    .pt-xxl-0 {
        padding-top: 0!important
    }

    .pt-xxl-1 {
        padding-top: .25rem!important
    }

    .pt-xxl-2 {
        padding-top: .5rem!important
    }

    .pt-xxl-3 {
        padding-top: 1rem!important
    }

    .pt-xxl-4 {
        padding-top: 1.5rem!important
    }

    .pt-xxl-5 {
        padding-top: 3rem!important
    }

    .pe-xxl-0 {
        padding-right: 0!important
    }

    .pe-xxl-1 {
        padding-right: .25rem!important
    }

    .pe-xxl-2 {
        padding-right: .5rem!important
    }

    .pe-xxl-3 {
        padding-right: 1rem!important
    }

    .pe-xxl-4 {
        padding-right: 1.5rem!important
    }

    .pe-xxl-5 {
        padding-right: 3rem!important
    }

    .pb-xxl-0 {
        padding-bottom: 0!important
    }

    .pb-xxl-1 {
        padding-bottom: .25rem!important
    }

    .pb-xxl-2 {
        padding-bottom: .5rem!important
    }

    .pb-xxl-3 {
        padding-bottom: 1rem!important
    }

    .pb-xxl-4 {
        padding-bottom: 1.5rem!important
    }

    .pb-xxl-5 {
        padding-bottom: 3rem!important
    }

    .ps-xxl-0 {
        padding-left: 0!important
    }

    .ps-xxl-1 {
        padding-left: .25rem!important
    }

    .ps-xxl-2 {
        padding-left: .5rem!important
    }

    .ps-xxl-3 {
        padding-left: 1rem!important
    }

    .ps-xxl-4 {
        padding-left: 1.5rem!important
    }

    .ps-xxl-5 {
        padding-left: 3rem!important
    }
}

@media screen and (min-width:991.98px) {
    h1 {
        font-size: 58px;
        line-height: 1
    }

    h2 {
        font-size: 48px;
        line-height: 1.1
    }

    h3 {
        font-size: 30px;
        line-height: 1.2
    }

    h4 {
        Line-height: 1.1
    }

    .section {
        padding: 180px 0
    }

    .section-nopb {
        padding: 180px 0 0
    }
}

@media (min-width:1200px) {
    .col-xl {
        -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%
    }

    .row-cols-xl-auto>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-xl-1>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-xl-2>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-xl-3>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.3333333333%
    }

    .row-cols-xl-4>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-xl-5>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-xl-6>* {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.6666666667%
    }

    .col-xl-auto {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto
    }

    .col-xl-1 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 8.33333333%
    }

    .col-xl-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 16.66666667%
    }

    .col-xl-3 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 25%
    }

    .col-xl-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 33.33333333%
    }

    .col-xl-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 41.66666667%
    }

    .col-xl-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 50%
    }

    .col-xl-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 58.33333333%
    }

    .col-xl-8 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 66.66666667%
    }

    .col-xl-9 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 75%
    }

    .col-xl-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 83.33333333%
    }

    .col-xl-11 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 91.66666667%
    }

    .col-xl-12 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%
    }

    .offset-xl-0 {
        margin-left: 0
    }

    .offset-xl-1 {
        margin-left: 8.33333333%
    }

    .offset-xl-2 {
        margin-left: 16.66666667%
    }

    .offset-xl-3 {
        margin-left: 25%
    }

    .offset-xl-4 {
        margin-left: 33.33333333%
    }

    .offset-xl-5 {
        margin-left: 41.66666667%
    }

    .offset-xl-6 {
        margin-left: 50%
    }

    .offset-xl-7 {
        margin-left: 58.33333333%
    }

    .offset-xl-8 {
        margin-left: 66.66666667%
    }

    .offset-xl-9 {
        margin-left: 75%
    }

    .offset-xl-10 {
        margin-left: 83.33333333%
    }

    .offset-xl-11 {
        margin-left: 91.66666667%
    }

    .g-xl-0,.gx-xl-0 {
        --bs-gutter-x: 0
    }

    .g-xl-0,.gy-xl-0 {
        --bs-gutter-y: 0
    }

    .g-xl-1,.gx-xl-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-xl-1,.gy-xl-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-xl-2,.gx-xl-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-xl-2,.gy-xl-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-xl-3,.gx-xl-3 {
        --bs-gutter-x: 1rem
    }

    .g-xl-3,.gy-xl-3 {
        --bs-gutter-y: 1rem
    }

    .g-xl-4,.gx-xl-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-xl-4,.gy-xl-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-xl-5,.gx-xl-5 {
        --bs-gutter-x: 3rem
    }

    .g-xl-5,.gy-xl-5 {
        --bs-gutter-y: 3rem
    }
}




