.skills_img {
    max-height: 700px;
    overflow: hidden;
    position: relative;
    margin-top: 40px;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1
}

.skills_img::before {
    float: left;
    padding-top: 107.6923076923077%;
    content: ''
}

.skills_img::after {
    display: block;
    content: '';
    clear: both
}

.skills_img-img {
    display: block;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%
}

.skills_list-item {
    margin-bottom: 30px
}

.skills_list-item:last-of-type {
    margin-bottom: 0
}

.skills_list-item .label {
    text-transform: uppercase;
    font-family: Archivo,sans-serif;
    font-weight: 600;
    display: block;
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 23px
}

.skills_list-item .progressLine svg {
    max-height: 15px
}

.skills_list-item .progressLine .progress-text {
    top: -35px
}

@media screen and (min-width:575.98px) {
    .skills_list-item .label {
        margin-bottom: 0
    }

    .skills_list-item .progressLine {
        padding-right: 70px;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 339px
    }

    .skills_list-item .progressLine .progress-text {
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%)
    }
}

@media screen and (min-width:767.98px) {
    .skills_list-item .progressLine {
        max-width: 460px
    }
}

@media screen and (min-width:991.98px) {
    .advantages_header-list_item {
        margin-bottom: 30px
    }

    .skills_list-item .progressLine {
        max-width: 700px
    }
}

@media screen and (min-width:1199.98px) {
    .skills_list-item .progressLine {
        max-width: 339px
    }

    .skills_img {
        max-width: 645px;
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1;
        -ms-flex-negative: 1;
        flex-shrink: 1;
        margin-top: 0
    }

    .skills .wrapper--skills {
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 494px;
        margin-left: 80px
    }
}
.imgContainer-skills{
    overflow:hidden;
    margin-bottom:30px;
}

@media screen and (min-width:991.98px){
    .skillsContainer .wrapper{
        padding-left:50px;
    }
}