@font-face {
    font-family:icomoon;src:url(../fonts/icomoon.woff) format('woff'),url(../fonts/icomoon.woff2) format('woff2');font-weight:400;font-style:normal;font-display:block
}

[class*=" icon-"],[class^=icon-] {
    font-family: icomoon!important;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.icon-arrow_left:before {
    content: "\e901"
}

.icon-arrow_down:before {
    content: "\e913"
}

.icon-comment:before {
    content: "\e914"
}

.icon-inbox:before {
    content: "\e912"
}

.icon-minus:before {
    content: "\e903"
}

.icon-plus:before {
    content: "\e904"
}

.icon-arrow_right:before {
    content: "\e909"
}

.icon-arrow_up:before {
    content: "\e911"
}

.icon-chevron_right:before {
    content: "\e902"
}

.icon-check:before {
    content: "\e90a"
}

.icon-play:before {
    content: "\e900"
}

.icon-call:before {
    content: "\e90c"
}

.icon-location:before {
    content: "\e90b"
}

.icon-star:before {
    content: "\e90e"
}

.icon-bookmark:before {
    content: "\e90f"
}

.icon-search:before {
    content: "\e910"
}

.icon-whatsapp:before {
    content: "\e905"
}

.icon-twitter:before {
    content: "\e906"
}

.icon-instagram:before {
    content: "\e907"
}

.icon-facebook:before {
    content: "\e908"
}

:root {
    --bs-blue: #0d6efd;
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #fd7e14;
    --bs-yellow: #ffc107;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-white: #fff;
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-gray-100: #f8f9fa;
    --bs-gray-200: #e9ecef;
    --bs-gray-300: #dee2e6;
    --bs-gray-400: #ced4da;
    --bs-gray-500: #adb5bd;
    --bs-gray-600: #6c757d;
    --bs-gray-700: #495057;
    --bs-gray-800: #343a40;
    --bs-gray-900: #212529;
    --bs-primary: #0d6efd;
    --bs-secondary: #6c757d;
    --bs-success: #198754;
    --bs-info: #0dcaf0;
    --bs-warning: #ffc107;
    --bs-danger: #dc3545;
    --bs-light: #f8f9fa;
    --bs-dark: #212529;
    --bs-primary-rgb: 13,110,253;
    --bs-secondary-rgb: 108,117,125;
    --bs-success-rgb: 25,135,84;
    --bs-info-rgb: 13,202,240;
    --bs-warning-rgb: 255,193,7;
    --bs-danger-rgb: 220,53,69;
    --bs-light-rgb: 248,249,250;
    --bs-dark-rgb: 33,37,41;
    --bs-white-rgb: 255,255,255;
    --bs-black-rgb: 0,0,0;
    --bs-body-color-rgb: 33,37,41;
    --bs-body-bg-rgb: 255,255,255;
    --bs-font-sans-serif: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    --bs-font-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: #212529;
    --bs-body-bg: #fff
}
.ternary {
    color: #0da574
}

h1,h2,h3,h4,h5,h6 {
    font-family: Archivo,sans-serif;
    font-weight: 700
}

h1 {
    font-size: 40px;
    line-height: 1.2
}

h2 {
    font-size: 36px;
    line-height: 1.1
}

h3,h4 {
    font-size: 26px;
    line-height: 1.2
}

h5 {
    font-size: 18px;
    line-height: 1.2
}

h6 {
    font-size: 14px;
    line-height: 1.5
}

.link-arrow {
    text-transform: uppercase;
    font-size: 15px;
    line-height: 23px;
    color: #0da574;
    letter-spacing: 1px;
    font-family: Archivo,sans-serif;
    font-weight: 600
}

.link-arrow--alt {
    color: #ffc631
}

.link-arrow i {
    margin-left: 5px;
    -webkit-transition: margin-left .3s ease-in-out;
    -o-transition: margin-left .3s ease-in-out;
    transition: margin-left .3s ease-in-out;
    font-size: 16px
}

.link-arrow:hover i {
    margin-left: 10px
}

.link-arrow {
    text-transform: uppercase;
    font-size: 15px;
    line-height: 23px;
    color: #0da574;
    letter-spacing: 1px;
    font-family: Archivo,sans-serif;
    font-weight: 600
}


.btn {
    font-family: Archivo,sans-serif;
    font-weight: 700;
    padding: 18px 30px;
    color: #000810;
    font-size: 18px;
    line-height: 23px;
    -webkit-transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    background: #0aa574;
    text-transform: uppercase;
    position: relative;
    border-radius: 30px;
    bottom: 0;
    display: inline-block;
    text-align: center
}

.btn:not(.btn--static):focus,.btn:not(.btn--static):hover {
    bottom: 5px
}

.btn-play {
    width: 55px;
    height: 55px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
    -ms-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
    border: 2px solid #001f3f;
    border-radius: 50%;
    -webkit-transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    color: #001f3f;
    font-size: 24px
}

.btn-play i {
    -webkit-transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    transition: .3s ease-in-out
}

.btn-play:focus,.btn-play:hover {
    border-color: #ffc631
}

.btn-play:focus i,.btn-play:hover i {
    color: #ffc631
}

.mediaLink_item {
    margin-right: 20px
}

.mediaLink_item:last-of-type {
    margin-right: 0
}

.mediaLink_item-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 18px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #001f3f;
    color: #fff;
    -webkit-transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    line-height: 30px
}

.mediaLink_item-link:focus,.mediaLink_item-link:hover {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    background-color: #ffc631
}

.mediaLink--alt .mediaLink_item-link {
    color: #001f3f;
    background: #ffc631
}

