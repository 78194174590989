.faq_header {
    margin-bottom: 40px;
}

@media screen and (min-width:1199.98px) {
    .faq_header {
        max-width: 472px;
        margin-top: 137px;
        margin-right: 30px
    }

    .faq_sectionAccordion {
        -ms-flex-negative: 1;
        flex-shrink: 1;
        max-width: 740px
    }
}

.faq_header {
    margin-bottom: 40px
}

.faq_header .text {
    margin: 30px 0 40px
}

@media screen and (min-width:575.98px) {
    .faq_header .btn {
        width: unset;
        margin: 0
    }
}

@media screen and (min-width:991.98px) {
    .faq_header .btn {
        margin: 0 auto
    }
}


.faq_header .text {
    margin: 30px 0 40px
}

.faq_header .btn {
    width: 100%
}

@media screen and (min-width:575.98px) {
    .faq_header .btn {
        width: unset;
        margin: 0
    }
}

@media screen and (min-width:991.98px) {
    .faq_header .btn {
        margin: 0 auto
    }

    .sectionAccordion-trigger {
        text-align: left
    }
}

@media screen and (min-width:1199.98px) {
    .faq_header {
        max-width: 472px;
        margin-top: 137px;
        margin-right: 30px
    }

    .faq_sectionAccordion {
        -ms-flex-negative: 1;
        flex-shrink: 1;
        max-width: 740px
    }
}
@media screen and (min-width:991.98px) {
    .faq_header .btn {
        margin: 0 auto
    }

    .sectionAccordion-trigger {
        text-align: left
    }
}

@media screen and (min-width:1199.98px) {
    .faq_header {
        max-width: 472px;
        margin-top: 137px;
        margin-right: 30px
    }

    .faq_sectionAccordion {
        -ms-flex-negative: 1;
        flex-shrink: 1;
        max-width: 740px
    }
}

.sectionAccordion-wrapper {
     box-shadow: 0 0 20px 0 rgba(0,0,0,.1);
    -webkit-transition: .3s ease-in;
    -o-transition: .3s ease-in;
    transition: .3s ease-in;
    padding: 10px 15px;
    margin-bottom: 30px;
    border-radius: 30px;
}

.sectionAccordion-wrapper:last-of-type {
    margin-bottom: 0
}

.sectionAccordion-wrapper.expanded {
   
    box-shadow: 0 0 20px 0 rgba(0,0,0,.1);
    padding: 15px
}

.sectionAccordion-wrapper.expanded .sectionAccordion-trigger {
    color: #0da574
}

.sectionAccordion-wrapper.expanded .sectionAccordion-trigger_icon .line {
    background: #0da574
}

.sectionAccordion-wrapper.expanded .sectionAccordion-trigger_icon .line--y {
    left: 0;
    top: 50%;
    -webkit-transform: rotate(0) translateX(0) translateY(0);
    -ms-transform: rotate(0) translateX(0) translateY(0);
    transform: rotate(0) translateX(0) translateY(0)
}

.sectionAccordion-trigger {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    font-family: Archivo,sans-serif;
    font-weight: 700;
    -webkit-transition: .3s ease-in;
    -o-transition: .3s ease-in;
    transition: .3s ease-in
}

.sectionAccordion-trigger .question {
    display: block;
    text-align: left;
    font-size: 24px;
    line-height: 28px
}

.sectionAccordion-trigger_icon {
    font-size: 18px;
    line-height: 24px;
    display: block;
    margin-left: 10px
}

.sectionAccordion-content {
    max-height: 0;
    overflow: hidden;
    -webkit-transition: max-height .2s;
    -o-transition: max-height .2s;
    transition: max-height .2s
}

.sectionAccordion-content .text {
    margin-top: 22px;
    max-height: 0;
    -webkit-transition: max-height .2s;
    -o-transition: max-height .2s;
    transition: max-height .2s
}

.sectionAccordion-content.show,.sectionAccordion-content.show .text {
    max-height: 1000px
}

@media screen and (min-width:575.98px) {
    .sectionAccordion-wrapper {
        padding: 20px 30px
    }

    .sectionAccordion-wrapper.expanded {
        padding: 30px
    }
}

@media screen and (min-width:767.98px) {
    .sectionAccordion-trigger .question {
        font-size: 26px;
        line-height: 32px
    }
}


