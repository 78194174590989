.designImage{
    width:50%;
    position:absolute;
    top:110px;
    right:110px;
}
.imgContainer{
    display:flex;
    justify-content: center;

}
