.galleryContainer>.col-lg-3{
    padding:0px;
    background-size: 100%;
}
.galleryContainer>.col-lg-3>div{
    padding:50px;
    padding-top:140px;
    color:white;
    cursor:pointer;
    transition: .5s ease-in-out;
}
.galleryContainer>.col-lg-3>div .lineTitle{
    margin-bottom:20px;
}
.galleryContainer>.col-lg-3>div h4{
    margin-bottom:20px;
}
.text-warning{
    color:#ffc631
}
.visible-gallery{
    opacity:1;
}
.invisible-gallery{
    opacity:0;
}