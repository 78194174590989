/* .footer {
    text-align: center;
}
.footer_title_block{
    background: #00162c;
    text-align: center;
    padding-top:30px;
    padding-bottom:30px;
    
}

.contactSection--footer{
    padding-left:20px;
    padding-right:20px;
}
.footer_main-block .brand,.footer_main-block_title {
    margin-bottom: 20px
}

.footer_main-block_form {
    margin-top: 20px
}

.footer_main-block_form .btn,.footer_main-block_form .field {
    width: 100%;
}

.footer_main-block_form .field {
    background: #fff;
    border: 1px solid transparent;
    padding: 17px 19px;
    -webkit-transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    color: #000810;
    font-size: inherit;
    line-height: inherit;
    margin-bottom: 20px
}

.footer_main-block_form .field::-webkit-input-placeholder {
    color: #a9a9a9;
    -webkit-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out;
    font-size: 16px;
    line-height: 23px
}

.footer_main-block_form .field::-moz-placeholder {
    color: #a9a9a9;
    -moz-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out;
    font-size: 16px;
    line-height: 23px
}

.footer_main-block_form .field:-ms-input-placeholder {
    color: #a9a9a9;
    -ms-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out;
    font-size: 16px;
    line-height: 23px
}

.footer_main-block_form .field::-ms-input-placeholder {
    color: #a9a9a9;
    -ms-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out;
    font-size: 16px;
    line-height: 23px
}

.footer_main-block_form .field::placeholder {
    color: #a9a9a9;
    -webkit-transition: opacity .3s ease-in-out;
    -o-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out;
    font-size: 16px;
    line-height: 23px
}

.footer_main-block_form .field:focus {
    border-color: #ffc631
}

.footer_main-block_form .field:focus::-webkit-input-placeholder {
    opacity: 0
}

.footer_main-block_form .field:focus::-moz-placeholder {
    opacity: 0
}

.footer_main-block_form .field:focus:-ms-input-placeholder {
    opacity: 0
}

.footer_main-block_form .field:focus::-ms-input-placeholder {
    opacity: 0
}

.footer_main-block_form .field:focus::placeholder {
    opacity: 0
}


.footer_main-block_form .btn {
    padding: 18px 20px
}

.footer_main-block_form .btn:focus,.footer_main-block_form .btn:hover {
    background: #ffd464
}

.footer_main-block .group-wrapper a {
    position:relative;
}
.footer_main-block .group-wrapper .icon {
    color: #ffc631;
    font-size: 35px !important;
    margin-right: 10px;
    position:absolute;
    top:0;
}

.footer_main-block .group-wrapper .group {
    text-align: left;
    line-height: 23px
}
.footer_main-block .group-wrapper .group a,.footer_main-block .group-wrapper .group span {
    display: inline-block
}

.footer_main-block .group-wrapper .group a {
    -webkit-transition: opacity .3s ease-in-out;
    -o-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out
}

.footer_main-block .group-wrapper .group a:focus,.footer_main-block .group-wrapper .group a:hover {
    opacity: .8
}
.footer_main-block_nav .list-item {
    margin-bottom: 13px
}

.footer_main-block_nav .list-item:last-of-type {
    margin-bottom: 0
}

.footer_main-block_nav .list-item .link {
    font-family: Archivo,sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative
}

.footer_main-block_nav .list-item .link::before {
    content: '\e902';
    font-family: icomoon;
    position: absolute;
    font-size: 14px;
    color: #ffc631;
    left: -15px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: opacity .3s ease-in-out;
    -o-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out;
    opacity: 0
}

.footer_main-block_nav .list-item .link:focus:before,.footer_main-block_nav .list-item .link:hover:before {
    opacity: 1
}

.footer_secondary {
    border-top: 1px solid #a9a9a9;
    margin-top: 60px;
    padding-top: 60px
}

.footer_secondary-copyright {
    text-align: center;
    margin: 0 0 40px
}

.footer_secondary-copyright span {
    display: inline-block
}

@media screen and (min-width:575.98px) {
    .footer_main-block_lineTitle {
        width: 100%;
        margin: 0 auto
    }

    .footer_main-block_lineTitle--newsletter {
        max-width: 330px
    }

    .footer_main-block_lineTitle--brand {
        max-width: 314px
    }

    .footer_main-block_form {
        max-width: 330px;
        width: 100%;
        margin: 20px auto 0
    }

    .footer_main-block_form .field {
        margin-bottom: 0
    }

    .footer_main-block_form .btn {
        width: auto
    }
}
.poweredByContainer{
    margin-top:50px;
}
.poweredByContainer p{
    color:grey;
}
.poweredByContainer p{
    margin-right:-20px;
    margin-left:20px;
}
.copyrightContainer{
    background:#00162c;
    width:100%;
    margin-top:70px;
    text-align:center;
    padding:10px;
}
.copyrightContainer > h5{
    color:grey;
}
.footer_main-block_nav > .displayFlex .secondFlexRow{
    margin-left:30px;
}
@media screen and (min-width:991.98px) {
    .footer {
        text-align: left
    }

    .footer_secondary-copyright {
        max-width: 350px;
        text-align: left;
        margin: 0
    }
}
@media screen and (min-width:1199.98px) {
    .footer_main-block_lineTitle--brand {
        margin: 0 auto 0 0
    }
}
.footer_main-block_form input{
    width:80% !important;
}
@media screen and (max-width:575px){
    .footer_main-block_form{
        padding-left:10px;
        padding-right:10px;
    }
    .footer_main-block_form input{
        width:100% !important;
    }
}
.contactSection--footer{
    text-align: left !important;
}
@media screen and (min-width:767.98px){
    .containerContacts-footer{
        padding-left:10%;
    }
}

@media screen and (max-width:991.98px){
    .poweredByContainer{
        display:flex !important;
        flex-flow: column !important;
        justify-content: center !important;
    }
    .poweredByContainer img{
        margin-left:50px !important; 
    }
    .footer_secondary-copyright{
        margin-bottom:-10px !important;
        color:white !important;
        font-weight: bold;
    }
    .showInBig{
        display:none;
    }
    .callContact{
        margin-left:0px !important;
    }
    .footer_main-block_nav > .displayFlex{
        flex-flow:column !important;
    }
    .footer_main-block_nav > .displayFlex .secondFlexRow{
        margin-left:0px !important;
        margin-top:15px;
    }
    .group-wrapper{
        text-align:center;
    }
    .callIcon{
        top:5px !important;
        right:55px;
    }
    .locationIcon{
        top:-10px !important;
        right:-25px !important;
    }
    .mailIcon{
        left:80px;
    }
    .ourHandlesText{
        margin-top:-10px !important;
    }
    .usefulLinks--footer{
        margin-top:80px !important;
    }
}
@media screen and (max-width:767.98px){
    .poweredByContainer{
        display:flex !important;
        flex-flow: column !important;
        justify-content: center !important;
    }
    .footer_secondary-copyright{
        margin-bottom:-10px !important;
        margin-right:10px !important;
        color:white !important;
        font-weight: bold;
    }
    .poweredByContainer img{
        margin-left:0px !important; 
    }
}
@media screen and (min-width:991.98px){
    .showInSmall{
        display:none;
    }
}
.containerContacts-footer,.lastColumn--footer {
    padding-top:50px;
}
.containerContacts-footer .icon{
    font-size:25px !important;
    transition: .25s ease-in-out;
    cursor:pointer;
}
.containerContacts-footer .icon:hover{
    font-size:30px !important;
}
.iconCollections-contacts{
    display:flex;
    flex-flow: column;
}
.iconCollections-contacts h4{
    margin-top:50px;
    margin-bottom:20px;
}
.iconCollections-contacts a {
    color:grey;
}
.footer_main-block .brandText{
    margin-bottom:20px;
}

.iconContainer--footer{
    margin-top:10px;
    justify-content: center;
}
.iconContainer--footer>li{
    list-style:none;
    margin-right:20px;
}
.footer_secondary .socials {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.footer_secondary .socials_item-link {
    background: #02a574;
    color: #ffffff;
}


.socials {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 20px
}
@media screen and (min-width:991.98px) {
    .footer {
        text-align: left
    }

    .footer_secondary-copyright {
        max-width: 350px;
        text-align: left;
        margin: 0
    }
}
.socials_item-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 18px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #02a574;
    color: #fff;
    -webkit-transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    line-height: 30px;
    will-change: transform
}

.socials_item-link:focus,.socials_item-link:hover {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    background-color: #02a574;
} */
.dk-footer .footer-widget p {
    margin-bottom: 27px;
}
.dk-footer  {
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    line-height: 28px;
}

.dk-footer .animate-border {
  position: relative;
  display: block; 
  width: 115px;
  height: 3px;
  background: #ffc631; }

.dk-footer .animate-border:after {
  position: absolute;
  content: "";
  width: 35px;
  height: 3px;
  left: 0;
  bottom: 0;
  border-left: 10px solid #fff;
  border-right: 10px solid #fff;
  -webkit-animation: animborder 2s linear infinite;
  animation: animborder 2s linear infinite; }

@-webkit-keyframes animborder {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px); }
  100% {
    -webkit-transform: translateX(113px);
    transform: translateX(113px); } }

@keyframes animborder {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px); }
  100% {
    -webkit-transform: translateX(113px);
    transform: translateX(113px); } }

.dk-footer .animate-border .border-white:after {
  border-color: #fff; }

.dk-footer .animate-border .border-yellow:after {
  border-color: #F5B02E; }

.dk-footer .animate-border.dk-footer .border-orange:after {
  border-right-color: #007bff;
  border-left-color: #007bff; }

.dk-footer .animate-border .border-ash:after {
  border-right-color: #EEF0EF;
  border-left-color: #EEF0EF; }

.dk-footer .animate-border .border-offwhite:after {
  border-right-color: #F7F9F8;
  border-left-color: #F7F9F8; }

/* Animated heading border */
@keyframes primary-short {
  0% {
    width: 15%; }
  50% {
    width: 90%; }
  100% {
    width: 10%; } }

@keyframes primary-long {
  0% {
    width: 80%; }
  50% {
    width: 0%; }
  100% {
    width: 80%; } } 

.dk-footer {
  padding: 75px 0 0;
  position: relative;
  z-index: 2; }
  .dk-footer .contact-us {
    margin-top: 0;
    margin-bottom: 30px;
    padding-left: 80px; }
    .dk-footer .contact-us .contact-info {
      margin-left: 50px; }
    .dk-footer .contact-us.contact-us-last {
      margin-left: -80px; }
 

.dk-footer-box-info {
  background: #001427;
  padding: 40px;
  text-align:center;
  z-index: 2; }
  .dk-footer-box-info .footer-social-link h3 {
    color: #fff;
    font-size: 24px;
    margin-bottom: 25px; }
  .dk-footer-box-info .footer-social-link ul {
    list-style-type: none;
    padding: 0;
    margin: 0; }
  .dk-footer-box-info .footer-social-link li {
    display: inline-block; }
  .dk-footer-box-info .footer-social-link a i {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    background: #000;
    margin-right: 5px;
    color: #fff; }
    .dk-footer-box-info .footer-social-link a .icon-facebook {
      background-color: #3B5998; }
    .dk-footer-box-info .footer-social-link a i.fa-twitter {
      background-color: #55ACEE; }
    .dk-footer-box-info .footer-social-link a i.fa-linkedin {
      background-color: #0976B4; }
    .dk-footer-box-info .footer-social-link a .icon-whatsapp{
      background-color: #075e54; }

.footer-awarad {
  margin-top: 285px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
  -moz-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .footer-awarad p {
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    margin-left: 20px;
    padding-top: 15px; }

.footer-info-text {
  margin: 26px 0 32px; }

.footer-left-widget {
  padding-left: 80px; }

.footer-widget .section-heading {
  margin-bottom: 35px; }

.footer-widget h3 {
  font-size: 24px;
  color: #fff;
  position: relative;
  margin-bottom: 15px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content; }

.footer-widget ul {
  width: 50%;
  float: left;
  list-style: none;
  margin: 0;
  padding: 0; }

.footer-widget li {
  margin-bottom: 18px; }

.footer-widget p {
  margin-bottom: 27px; }

.footer-widget a {
  color: #878787;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }
  .footer-widget a:hover {
    color: #ffc631; }

.footer-widget:after {
  content: "";
  display: block;
  clear: both; }

.dk-footer-form {
  position: relative; }
  .dk-footer-form input[type=email] {
    padding: 14px 28px;
    border-radius: 50px;
    background: #2E2E2E;
    border: 1px solid #2E2E2E; }
  .dk-footer-form input::-webkit-input-placeholder, .dk-footer-form input::-moz-placeholder, .dk-footer-form input:-ms-input-placeholder, .dk-footer-form input::-ms-input-placeholder, .dk-footer-form input::-webkit-input-placeholder {
    color: #878787;
    font-size: 14px; }
  .dk-footer-form input::-webkit-input-placeholder, .dk-footer-form input::-moz-placeholder, .dk-footer-form input:-ms-input-placeholder, .dk-footer-form input::-ms-input-placeholder, .dk-footer-form input::placeholder {
    color: #878787;
    font-size: 14px; }
  .dk-footer-form button[type=submit] {
    position: absolute;
    top: 0;
    right: 0;
    padding: 12px 24px 12px 17px;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    border: 1px solid #007bff;
    background: #007bff;
    color: #fff; }
  .dk-footer-form button:hover {
    cursor: pointer; }

/* ==========================

    Contact

=============================*/
.dk-footer .contact-us {
  position: relative;
  z-index: 2;
  margin-top: 65px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.dk-footer .contact-icon {
  position: absolute; 
}
  .dk-footer .contact-icon .icon {
    font-size: 36px;
    top: -40px;
    position: relative;
    color: #ffc631; 
    cursor:pointer;
}

.dk-footer .contact-info {
  margin-left: 75px;
  color: #fff; }
  .dk-footer .contact-info h3 {
    font-size: 20px;
    color: #fff;
    margin-bottom: 0; }

.dk-footer .copyright {
  padding: 28px 0;
  margin-top: 55px;
  background-color: #001427; }
  .dk-footer .copyright span,
  .dk-footer .copyright a {
    color: #878787;
    -webkit-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear; }
  .dk-footer .copyright a:hover {
    color:#007bff; }

.dk-footer .copyright-menu ul {
  text-align: right;
  margin: 0; }

.dk-footer .copyright-menu li {
  display: inline-block;
  padding-left: 20px; }

.dk-footer .back-to-top {
  position: relative;
  z-index: 2; }
  .dk-footer .back-to-top .btn-dark {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    padding: 0;
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: #2e2e2e;
    border-color: #2e2e2e;
    display: none;
    z-index: 999;
    -webkit-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear; }
    .dk-footer .back-to-top .btn-dark:hover {
      cursor: pointer;
      background: #FA6742;
      border-color: #FA6742; }

      @media screen and (max-width:767.98px) {
        .dk-footer .contact-us{
            display:flex;
            justify-content: center;
            align-items: center;
            padding:0px;
        }
        .dk-footer .contact-us h3{
            margin-top:10px;
        }
        .dk-footer .contact-us > *{
            margin:0;
        }
        .dk-footer .contact-us-last{
            margin-left:0px !important;
            margin-top:20px;
        }
    }
    @media screen and (max-width:991.98px) {
        .footer-widget{
            padding-left:50px;
            margin-top:30px;
        }
    }