.projects_header {
    margin-bottom: 30px
}

.projects_list-item {
    margin-bottom:80px;
}
.projects_list-item hr{
    color:rgb(233, 233, 233);
}
.projects_list-item[data-order="1"] {
    margin-top: 0
}

.projects_list-item .wrapper {
    height: 100%
}

.projects_list-item_title {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin-top: 20px;
    margin-bottom:20px;
}

.projects_list-item .text-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1
}

.projects_list-item .img-wrapper {
    overflow: hidden;
    max-height: 500px;
    width: 100%;
    position: relative
}

.projects_list-item .img-wrapper::before {
    float: left;
    padding-top: 80%;
    content: ''
}

.projects_list-item .img-wrapper::after {
    display: block;
    content: '';
    clear: both
}

.projects_list-item .img-wrapper img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: block;
    -o-object-fit: cover;
    object-fit: cover;
    -webkit-transition: -webkit-transform .5s ease-in-out;
    transition: transform .5s ease-in-out;
    -o-transition: transform .5s ease-in-out;
    transition: transform .5s ease-in-out,-webkit-transform .5s ease-in-out
}

.projects_list-item .img-wrapper:hover img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1)
}

.projects_list-item_info {
    position: relative;
    margin-top: 15px;
    padding-top: 15px
}

.projects_list-item_info .separator {
    position: absolute;
    width: 100%;
    height: 1px;
    background: #a9a9a9;
    top: 0;
    left: 0
}

.projects_list-item_info .location {
    width: 100%;
    margin-bottom: 15px
}

.projects_list-item_info .location .icon {
    color: #ffc631;
    font-size: 24px;
    margin-right: 10px
}

.projects_list-item_info .link-arrow {
    margin-right: auto
}

@media screen and (min-width:575.98px) {
    .projects_list-item_info .location {
        width: unset;
        margin-right: 15px;
        margin-bottom: 0
    }

    .projects_list-item_info .link-arrow {
        margin-left: auto;
        margin-right: unset
    }
}

@media screen and (min-width:767.98px) {
    .projects_list {
        margin: 0 -15px
    }

    .projects_list-item {
        padding: 0 15px
    }

    .projects_list-item[data-order="2"] {
        margin-top: 0
    }

    .projects_list-item_info .location {
        margin-bottom: 15px
    }

    .projects_list-item_info .link-arrow {
        margin-left: unset;
        margin-right: auto
    }
}

@media screen and (min-width:991.98px) {
    .projects_header {
        margin-bottom: 40px
    }

    .projects_list {
        margin: 0 -20px
    }

    .projects_list-item {
        padding: 0 20px;
        margin-top: 50px
    }

    .projects_list-item_info .location {
        width: 100%
    }
}

@media screen and (min-width:1199.98px) {
    .projects_list-item_info .location {
        width: unset;
        margin-right: 15px;
        margin-bottom: 0
    }

    .projects_list-item_info .link-arrow {
        margin-left: auto;
        margin-right: unset
    }

}
