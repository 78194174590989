.info_header {
    margin-bottom: 30px
}

.info_data {
    margin-bottom: 40px
}

.info_data-card {
    margin-top: 30px
}

.info_data-card[data-order="1"] {
    margin-top: 0
}

.info_data-card .wrapper {
   box-shadow: 0 0 20px 0 rgba(0,0,0,.1);
    padding: 30px;
    -webkit-transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    transition: .3s ease-in-out
}

.info_data-card .wrapper:hover {
    border-color: #ffc631
}

.info_data-card .icon {
    color: #0aa574;
    font-size: 32px;
    line-height: 30px
}

.info_data-card .title {
    margin: 15px 0 10px
}

.info_data-card .content {
    color: #202c38
}

.info_data-card .content .link {
    -webkit-transition: color .3s ease-in-out;
    -o-transition: color .3s ease-in-out;
    transition: color .3s ease-in-out
}

.info_data-card .content .link:focus,.info_data-card .content .link:hover {
    color: #0da574
}

.info_map {
    height: 330px
}

.info_map #map {
    height: 100%
}

@media screen and (min-width:767.98px) {
    .info_header {
        padding: 0 15px
    }

    .info_data {
        margin: 0 -15px
    }

    .info_data-card {
        padding: 0 15px
    }

    .info_data-card .wrapper {
        padding: 40px
    }

    .info_map {
        margin-top: 30px
    }

    .partners .row {
        margin: 0 -15px
    }

    .partners_card,.partners_header {
        padding: 0 15px
    }

    .partners_card[data-order="1"] {
        margin-top: 0
    }
}

@media screen and (min-width:1199.98px) {
    .info_map {
        margin-top: 60px
    }
}

@media screen and (min-width:1399.98px) {
    .info_data-card {
        margin-top: 0
    }
}

.partners_card {
    margin-top: 30px
}

.partners_card .wrapper {
    box-shadow: 0 0 20px 0 rgba(0,0,0,.1);
    padding: 51px 0
}

@media screen and (min-width:991.98px) {
    .info_map {
        height: 660px
    }

    .partners_card[data-order="2"] {
        margin-top: 0
    }
}
