.partners_card {
    margin-top: 30px
}

.partners_card .wrapper {
    box-shadow: 0 0 20px 0 rgba(0,0,0,.1);
    padding: 51px 0;
    flex-flow:column;
    align-items: center;
}
.partners_card .wrapper:hover{
    cursor:pointer;
    border:1px solid #0da574;
}
.partners_card .icons{
    font-size:90px;
    margin-bottom:20px;
}
.blackOpacity--products{
    background:rgba(0,0,0,.5);
    position:absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    margin:0 auto;
}
.blackOpacity--products h3{
    margin-top:40%;
    margin-left:40px;
}
@media screen and (min-width:767.98px) {
    .partners .row {
        margin: 0 -15px
    }

    .partners_card,.partners_header {
        padding: 0 15px
    }

    .partners_card[data-order="1"] {
        margin-top: 0
    }
}
@media screen and (max-width:1200px){
    .partners_header .title{
        font-size:32px;
    }
}
@media screen and (max-width:991.98px){
    .partners_header .title{
        font-size:35px !important;
    }

}

@media screen and (min-width:991.98px) {
    .partners_card[data-order="2"] {
        margin-top: 0
    }
}
