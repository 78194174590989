.swiper{
    z-index: 0 !important;
}
.servicesContainer{
    position:relative;
    isolation: isolate;
    width:100vw !important;
    padding-bottom:50px;
}
.servicesContainer::after{
    content:"";
    background:rgb(34, 37, 54);
    z-index:-1;
    inset:0;
    position:absolute;
    opacity:.8;
}