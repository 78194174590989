.contact>.container {
    display: flex;
}




.contact-info_group {
    font-family: Archivo, sans-serif;
    margin-bottom: 30px
}

.contact-info_group:last-of-type {
    margin-bottom: 0
}

.contact-info_group span {
    display: block
}

.contact-info_group .name {
    text-transform: uppercase;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 8px;
    color: #a9a9a9;
    font-weight: 500
}

.contact-info_group .content {
    font-weight: 600;
    font-size: 18px;
    line-height: 23px
}

.contact-info_group .content .link {
    -webkit-transition: color .3s ease-in-out;
    -o-transition: color .3s ease-in-out;
    transition: color .3s ease-in-out
}

.contact-info_group .content .link:focus,
.contact-info_group .content .link:hover {
    color: #0da574
}

@media screen and (min-width:575.98px) {
    .contact .mediaLink_item-link {
        width: 50px;
        height: 50px;
        font-size: 26px;
        line-height: 30px
    }
}

.contact {
    padding-top: 0
}

.contact_header .text {
    margin: 30px 0 40px
}

.contact_map {
    margin-top: 40px;
    height: 350px
}

.contact_map #map {
    width: 100%;
    height: 100%
}

.contactForm-header {
    margin-bottom: 50px;
}

@media screen and (min-width:991.98px) {
    .contact-info {
        margin-bottom: 87px
    }

    .contact-info_group {
        margin-bottom: 40px
    }

    .contact_map {
        margin-top: 100px;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        margin-left: 30px;
    }

    .smallMap {
        display: none;
    }
}

@media screen and (max-width:991.98px) {
    .finalPhone {
        margin-bottom: 50px;
    }
    .contact>.container{
        flex-flow:column;
    }
}

@media screen and (min-width:1399.98px) {
    .contact-wrapper {
        max-width: 500px
    }

    .contact_map {
        max-width: 660px;
        height: 543px;
        margin-left: 0
    }
}



.contact-form_btn,
.contact-form_field {
    width: 100%
}

.contact-form_field {
    font-size: inherit;
    line-height: inherit;
    padding: 17px 20px;
    border: 1px solid #a9a9a9;
    margin-bottom: 30px;
    color: #202c38;
    -webkit-transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    transition: .3s ease-in-out
}

.contact-form_field[data-type=message] {
    margin-bottom: 0;
    min-height: 164px
}

.contact-form_field::-webkit-input-placeholder {
    font-size: inherit;
    line-height: inherit;
    color: #a9a9a9;
    -webkit-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out
}

.contact-form_field::-moz-placeholder {
    font-size: inherit;
    line-height: inherit;
    color: #a9a9a9;
    -moz-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out
}

.contact-form_field:-ms-input-placeholder {
    font-size: inherit;
    line-height: inherit;
    color: #a9a9a9;
    -ms-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out
}

.contact-form_field::-ms-input-placeholder {
    font-size: inherit;
    line-height: inherit;
    color: #a9a9a9;
    -ms-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out
}

.contact-form_field::placeholder {
    font-size: inherit;
    line-height: inherit;
    color: #a9a9a9;
    -webkit-transition: opacity .3s ease-in-out;
    -o-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out
}

.contact-form_field:focus {
    border-color: #202c38
}

.contact-form_field:focus::-webkit-input-placeholder {
    opacity: 0
}

.contact-form_field:focus::-moz-placeholder {
    opacity: 0
}

.contact-form_field:focus:-ms-input-placeholder {
    opacity: 0
}

.contact-form_field:focus::-ms-input-placeholder {
    opacity: 0
}

.contact-form_field:focus::placeholder {
    opacity: 0
}

.contact-form_btn {
    margin-top: 40px;
}

.contact-info {
    max-width: 285px;
    margin-bottom: 40px
}

.contact-info_group {
    font-family: Archivo, sans-serif;
    margin-bottom: 30px
}

.contact-info_group:last-of-type {
    margin-bottom: 0
}

.contact-info_group span {
    display: block
}

.contact-info_group .name {
    text-transform: uppercase;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 8px;
    color: #a9a9a9;
    font-weight: 500
}

.contact-info_group .content {
    font-weight: 600;
    font-size: 18px;
    line-height: 23px
}

.contact-info_group .content .link {
    -webkit-transition: color .3s ease-in-out;
    -o-transition: color .3s ease-in-out;
    transition: color .3s ease-in-out
}

.contact-info_group .content .link:focus,
.contact-info_group .content .link:hover {
    color: #0da574
}

@media screen and (min-width:575.98px) {
    .contact-form_field--half {
        width: calc(50% - 15px)
    }

    .contact .mediaLink_item-link {
        width: 50px;
        height: 50px;
        font-size: 26px;
        line-height: 30px
    }
}

@media screen and (min-width:991.98px) {
    .contact-info {
        margin-bottom: 87px
    }

    .contact-info_group {
        margin-bottom: 40px
    }
}

.contact_header .text {
    margin: 30px 0
}

.contact_info {
    margin-bottom: 30px
}

.contact .wrapper--bg {
    margin-top: 50px;
    background-color: #f8f8f8;
    padding: 30px
}

@media screen and (min-width:767.98px) {
    .contact_info {
        margin-bottom: 40px
    }

    .contactForm {
        padding-right: 90px;
    }
}

@media screen and (max-width:767.98px) {
    .contact-form_btn {
        margin-bottom: 90px;
    }
}

.contacts_map {
    height: 330px
}

.contacts_map #map {
    height: 100%
}

@media screen and (min-width:991.98px) {
    .contact_header .text {
        margin: 20px 0 30px
    }

    .contact .wrapper--bg {
        margin-top: 0;
        padding: 60px
    }

    .contacts_map {
        height: 660px
    }
}
.contact_info-header{
    margin-bottom:30px;
}
.contact-faq{
    margin-top:150px;
}