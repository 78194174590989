.quoteTitle-icon {
    -webkit-animation: 2s ease-in-out infinite both rotate-center;
    animation: 2s ease-in-out infinite both rotate-center;
    position: absolute;
    z-index: 1;
    top: -20%;
    right: 0;
}

/* Animation */
@keyframes rotate-center {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

/* Media queries */
@media screen and (min-width:1399.98px) {
    .quoteTitle-icon {
        top: -66px;
        left: -80px
    }
}
