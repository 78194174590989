.sideNav-dashboard{
    overflow:auto;
    height:89vh;
    padding:20px;
}
.cream-dashboard{
    background:rgb(241, 241, 241);
}
.sideNav-dashboard h3{
    margin-bottom:30px;
}
.container-dashboard{
    margin-bottom:40px;
    cursor:pointer;
}
.selected-display-dashboard{
    padding-left:30px;
    margin-top:30px;
}
.titleInput-dashboard{
    font-size:30px;
    height:50px;
    width:100%; 
    margin:0 auto;
    border:1px solid grey;
    color:rgb(32, 32, 32);
    padding:20px;
    margin-bottom:30px;
}
.textInput-dashboard{
    width:100%;
    border:1px solid grey;
    height:200px;
    padding:20px;
}
.imageUploading-dashboard{
    margin-bottom:30px;
}

.selected-display-dashboard button{
    margin-right: 20px;
    margin-top:30px;
}
.deleteButton-dashboard{
    background:#d9534f !important;
    color:white;
}
.itemAdditionForm-dashboard{
    padding-top:20px;
    padding-left:5%;
    padding-right:5%;
}
.itemAdditionForm-dashboard button{
    margin-top:50px;
}
.articleEntryBox-dashboard{
    border:1px solid grey;
    height:30vh;
    width:100%;
    font-size:20px;
    padding:20px;
    margin-top:20px;
}
.noBorder{
    display:none;
    outline:none;
    border:none;
}
.plusIcon{
    font-size:90px !important;
    color:white;
}
.fileUpload{
    background:rgba(13, 165, 116, .5);
    display:flex;
    justify-content: center;
    align-items: center;
    padding:20px;
    height:350px;
    width:100%;
    cursor:pointer;
    margin-bottom:20px;
}
.sideNavSml-dashboard{
    padding:5px;
    margin-bottom:20px;
}
.sideNavSml-dashboard .icon{
    color:black;
    font-size:30px;
    cursor:pointer;
    margin-left:3%;
    color:#0da574;
}
.contentSml-dashboard{
    margin-top:30px;
    text-align:center;
    padding-left:3%;
    padding-right:3%;
    height:150px;
    overflow:auto;
}
@media screen and (max-width:767.98px){
    .sideNav-dashboard{
        display:none;
    }
}
@media screen and (min-width:767.98px){
    .sideNavSml-dashboard{
        display:none;
    }
}
@media screen and (max-width:767.98px){
    .buttonFlex-dashboard{
        display:flex;
        flex-flow:column;
    }
}
.display-dashboard{
    display:block;
}
.noDisplay-dashboard{
    display:none;
}
.dateDisplay-dashboard{
    font-size:13px;
    margin-top:10px;
    color:rgb(250, 250, 250);
}
.buttonUnclickable{
    opacity:.5;
    pointer-events: none;
}
.durationInput-dashboard,.costInput-dashboard{
    height:40px;
    margin-top:20px;
    border:1px solid grey;
    padding-left:20px;
}
.reviewTextArea{
    width:100%;
    border:1px solid grey;
    padding:20px;
}
.extraProductInput-dashboard{
    height:50px;
    border:1px solid grey;
    padding-left:10px;
    margin-right:20px;
    margin-bottom:20px;
}