.features_header .title {
    margin-bottom: 30px
}

.features_header-list {
    margin-bottom: 40px
}

.features_header-list_item {
    margin-bottom: 16px
}

.features_header-list_item .icon {
    color: #0da574;
    margin-right: 10px
}

.features_card {
    cursor: pointer;
    margin-top: 30px
}

.features_card[data-order="1"] {
    margin-top: 0
}

.features_card .wrapper {
    height: 100%;
    box-shadow: 0 0 20px 0 rgba(0,0,0,.1);
    -webkit-transition: border-color .3s ease-in-out;
    -o-transition: border-color .3s ease-in-out;
    transition: border-color .3s ease-in-out;
    padding: 30px
}

.features_card .wrapper:hover {
    cursor:pointer;
    border:1px solid #0da574;
}




.features_card-title {
    margin: 30px 0 20px
}

.features_card-icon * {
    fill: #ffc631
}

.features_card--alt .wrapper {
    border-color: #a9a9a9
}

.features_card--alt .wrapper:hover {
    cursor:pointer;
    border:1px solid #0da574;
}

.features_card--alt .features_card-icon * {
    fill: #001f3f
}

.features_card--alt .features_card-description {
    color: #001f3f
}
.features_video {
    margin-top: 60px;
    max-height: 580px;
    margin-bottom:100px;
    overflow: hidden
}
.features_video img {
    width: 100%;
    display: block;
    -o-object-fit: cover;
    object-fit: cover
}

/*Media queries*/
@media screen and (min-width:767.98px) {
    .features_header {
        padding: 0 15px
    }

    .features_header-list {
        margin-bottom: 0
    }

    .features_card {
        padding: 0 15px
    }

    .features_card[data-order="1"] {
        margin-top: 30px
    }
}

@media screen and (min-width:1399.98px) {
    .features_card .wrapper {
        padding: 50px
    }
}
