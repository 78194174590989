.timeLineContainer{
    position:absolute;
    top:0;
    left:30px;
    right:20px;
    z-index:2 !important;
    padding-left:5.2%;
    padding-right:5.2%;
}
.timeLineContainer .line{
    height:2px;
    width:75%;
    position:absolute;
    z-index: 2 !important;
    top:0px;
}
.timeLineContainer .col-lg-3{
    padding-left:0;
}
.timeLineContainer .circle,.timeStampSmaller .circle{
    height:20px;
    width:20px;
    background:#0aa574;
    margin-top:0px;
    border-radius:50%;
    position:relative;
}
.timeLineContainer .circle ::before{
    content:'';
    position:absolute;
    top:3px;
    left:5px;
    height:10px;
    width: 10px;
    border-radius:50%;
    filter:blur(5px);
    background:#0aa574;
    animation:animate 1s ease-in-out infinite alternate;
}
@keyframes animate{
    from{
        transform:scale(1);
    }
    to{
        transform:scale(1.5);
    }
}